import {
  UserWrapper,
  DetailsWrapper,
  ImageWrapper,
  Details,
  Image,
  Wrapper1,
  TextWrapper,
  Label,
  Data,
  TabWrapper,
  ButtonWrapper as ButtonWrap,
  TabButton,
} from "styles/commonStyle";
import {
  Action,
  BlockButton,
  BreadcrumbWrap,
  Container,
  Title,
  UnBlockButton,
  UserImage,
  UserNameWrapper,
} from "styles/dashboard";
import seprator from "assets/images/seprator.png";
import { ActionsWrapper, Icon, Wrapper } from "styles/pages/userManagement";
import userImage from "assets/images/userImage.png";
import { CiMail } from "react-icons/ci";
import { LuBuilding2, LuUser2 } from "react-icons/lu";
import { LuPhone } from "react-icons/lu";
import { CiLocationOn } from "react-icons/ci";
import { Breadcrumb, Tabs, TabsProps } from "antd";
import TableContainer from "components/TableContainer";
import { ColumnsType } from "antd/es/table";
import { DataType, TodayJobData } from "constants/tableData";
import viewIcone from "assets/images/view.png";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import UserIcon from "../../../assets/images/no-user.jpg";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
  UpDateButton,
} from "styles/unAssignedJob";
import { FaRegEdit } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { CommonFilter } from "components";
import {
  DATE_FILTERS,
  PAGINATION_SIZE,
  filterInitialValue,
  shortenAddress,
} from "constants/common";
import { FilterValue } from "interfaces/iCommon";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { postApi } from "api/api.client";
import { APIS, APP_BASE_URL } from "api/api.constant";
import driverImage from "../../../assets/images/driverImage.png";
import moment from "moment";
import {
  disabledFutureDate,
  disabledPastAndTodayJob,
  disabledPastDate,
} from "components/dateInput";
import {
  calculateTotalPickupCount,
  capitalizeFirstLetter,
  shortenLocation,
} from "components/commonFunction";
interface UserDetail {
  data: any;
}

const UserJobDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get("tabs");
  const currentSetTab = queryParams.get("tab");
  const tabdata = ["Today's Jobs", "Upcoming Jobs", "Past Jobs"];
  const filterTab = ["Assigned", "Unassigned"];
  const [filter, setfilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGINATION_SIZE);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState<UserDetail["data"]>({});
  const [activeTab, setActiveTab] = useState(Number(currentTab) || 0);
  const [activeFilterTab, setActiveFilterTab] = useState(
    Number(currentSetTab) || 0
  );
  const [filterValue, setFilterValue] =
    useState<FilterValue>(filterInitialValue);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.GET_USER_BY_ID}/${id}`, {})
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  useEffect(() => {
    const handleSelect = () => {
      switch (activeFilterTab) {
        case 1:
          queryParams.set("tab", String(activeFilterTab));
          queryParams.delete("tabs");
          break;
        default:
          queryParams.set("tabs", String(activeTab));
          queryParams.delete("tab");
          break;
      }
      const newUrl = `${location.pathname}?${queryParams}`;
      window.history.replaceState(null, "", newUrl);
    };

    handleSelect();
  }, [activeTab, activeFilterTab]);

  const getPostApiRoute = (
    activeTab: number,
    activeFilterTab: number
  ): string => {
    if (activeFilterTab === 1) {
      return APIS.USER_UNASSIGNJOB;
    }
    switch (activeTab) {
      case 0:
        return APIS.USER_TODAYJOB;
      case 1:
        return APIS.USER_UPCOMINGJOB;
      case 2:
        return APIS.USER_PASTJOB;
      default:
        return APIS.USER_UNASSIGNJOB;
    }
  };

  const fetchJobData = (route: string) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(route, {
      ...filterValue,
      page: page,
      size: pageSize,
      userId: id,
    })
      .then((res) => {
        let response: any = res;
        setTableData(response?.data?.jobInfo);
        setTotalCount(response?.data?.pagination?.totalCount);
      })
      .catch((err) => {})
      .finally(() => {
        // setFilterValue(filterInitialValue);
        dispatch(toggleLoader({ loader: false }));
      });
  };

  useEffect(() => {
    fetchJobData(getPostApiRoute(activeTab, activeFilterTab));
  }, [triggerFilter, page, pageSize, refresh, activeTab, activeFilterTab]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Driver Name",
      key: "name",
      render: (_, record: any) => (
        <UserNameWrapper>
          <UserImage src={record?.photo !== null ? record?.photo : UserIcon} />
          {capitalizeFirstLetter(record?.full_name ? record?.full_name : "")}
        </UserNameWrapper>
      ),
    },

    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.locations[0]?.location ||
          record?.locations[0]?.addressLine1 +
            " " +
            record?.locations[0]?.addressLine2;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",

      render: (_, record: any) => {
        const lastLocation =
          record?.jobDropOff[record?.jobDropOff?.length - 1]
            ?.drop_off_location ||
          record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine1 +
            " " +
            record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine2;
        return <div>{lastLocation}</div>;
      },
    },
    {
      title: <Action>Total Stops</Action>,
      dataIndex: "totalStops",
      key: "Total Stops",
      render: (_, record) => {
        return <Action>{record?.totalStops}</Action>;
      },
    },
    {
      title: "Total Items",
      dataIndex: "totalItems",
      render: (_, record) => {
        return <div>{calculateTotalPickupCount(record?.locations)}</div>;
      },
    },

    {
      title: <Action>Actions</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper
            onClick={() =>
              navigate(`/user-management/${id}/todayJob/${record?._id}`)
            }
          >
            <Icon src={viewIcone} />
          </ActionsWrapper>
        );
      },
    },
  ];
  const UpcomingJobcolumns: ColumnsType<DataType> = [
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.locations[0]?.location ||
          record?.locations[0]?.addressLine1 +
            " " +
            record?.locations[0]?.addressLine2;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",
      render: (_, record: any) => {
        const lastLocation =
          record?.jobDropOff[record?.jobDropOff?.length - 1]
            ?.drop_off_location ||
          record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine1 +
            " " +
            record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine2;
        return <div>{lastLocation}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record: any) => (
        <div>{moment(record?.dates, "YYYY-MM-DD").format("ll")}</div>
      ),
    },
    {
      title: <Action>Total Stops</Action>,
      dataIndex: "totalStops",
      key: "Total Stops",
      render: (_, record) => {
        return <Action>{record?.totalStops}</Action>;
      },
    },
    {
      title: "Total Items",
      dataIndex: "totalItems",
      render: (_, record) => {
        return <div>{calculateTotalPickupCount(record?.locations)}</div>;
      },
    },

    {
      title: <Action>Actions</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper
            onClick={() =>
              navigate(`/user-management/${id}/upcoming/${record?._id}`)
            }
          >
            <Icon src={viewIcone} />
          </ActionsWrapper>
        );
      },
    },
  ];

  const PostJob: ColumnsType<DataType> = [
    {
      title: "Driver Name",
      key: "name",

      render: (_, record: any) => (
        <UserNameWrapper>
          <UserImage
            src={record?.photo !== null ? record?.photo : driverImage}
          />
          {capitalizeFirstLetter(record?.full_name ? record?.full_name : "")}
        </UserNameWrapper>
      ),
    },
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.locations[0]?.location ||
          record?.locations[0]?.addressLine1 +
            " " +
            record?.locations[0]?.addressLine2;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",
      render: (_, record: any) => {
        const lastLocation =
          record?.jobDropOff[record?.jobDropOff?.length - 1]
            ?.drop_off_location ||
          record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine1 +
            " " +
            record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine2;
        return <div>{lastLocation}</div>;
      },
    },
    {
      title: <Action>Total Stops</Action>,
      dataIndex: "totalStops",
      key: "Total Stops",
      render: (_, record) => {
        return <Action>{record?.totalStops}</Action>;
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => {
        return (
          <div>
            {Number(record?.amount || 0)?.toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        );
      },
    },
    {
      title: <Action>Status</Action>,
      key: "status",
      render: (_, record) => {
        return (
          <Action>
            {record?.status === "Cancelled" ? (
              <BlockButton
                style={{ backgroundColor: "#FFD5D2", color: "#F34235" }}
              >
                Cancelled
              </BlockButton>
            ) : (
              <UnBlockButton
                style={{ backgroundColor: "#C2FFCF", color: "#01851E" }}
              >
                Completed
              </UnBlockButton>
            )}
          </Action>
        );
      },
    },
    {
      title: <Action>Actions</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper
            onClick={() =>
              record?.status === "Cancelled"
                ? navigate(`/user-management/${id}/cancelled/${record?._id}`)
                : navigate(`/user-management/${id}/completed/${record?._id}`)
            }
          >
            <Icon src={viewIcone} />
          </ActionsWrapper>
        );
      },
    },
  ];

  const UnAssignedJob: ColumnsType<DataType> = [...columns];
  UnAssignedJob.splice(0, 1);
  UnAssignedJob.splice(4, 1, {
    title: <Action>Actions</Action>,
    key: "Action",
    render: (_, record) => {
      return (
        <ActionsWrapper
          onClick={() =>
            navigate(`/user-management/${id}/unassignedJob/${record?._id}`, {
              state: { data: data, record: record },
            })
          }
        >
          <Icon src={viewIcone} />
        </ActionsWrapper>
      );
    },
  });
  UnAssignedJob.splice(-1, 0, {
    title: "Vehicle Type",
    dataIndex: "vehicle",
    key: "Vehicle",
  });

  const onResetClick = () => {
    setFilterValue(filterInitialValue);
    setTriggerFilter(!triggerFilter);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <div>Todays's Job</div>,
      children: (
        <TableContainer
          title={"Today's Jobs"}
          colums={columns}
          data={tableData}
          activeFilterTab={activeFilterTab}
          activeTab={activeTab}
          onResetClick={onResetClick}
          setfilter={setfilter}
          filter={filter}
          filterValue={filterValue}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
    {
      key: "2",
      label: <div>Upcoming Jobs </div>,
      children: (
        <TableContainer
          title={"Upcoming Jobs"}
          data={tableData}
          onResetClick={onResetClick}
          colums={UpcomingJobcolumns}
          setfilter={setfilter}
          filter={filter}
          filterValue={filterValue}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
    {
      key: "3",
      label: <div>Past Jobs </div>,
      children: (
        <TableContainer
          amount={true}
          title={"Past Jobs"}
          data={tableData}
          onResetClick={onResetClick}
          colums={PostJob}
          setfilter={setfilter}
          filterValue={filterValue}
          filter={filter}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      ),
    },
  ];

  const handleOk = () => {
    setfilter(false);
  };

  const handleCancel = () => {
    setfilter(false);
  };

  const onSearchClick = () => {
    if (filterValue.date_from !== null && filterValue.date_to !== null) {
      setTriggerFilter(!triggerFilter);
      setfilter(!filter);
    }
  };

  let disabledDateFunction;
  let job_type;
  if (activeFilterTab === 0 && activeTab === 1) {
    disabledDateFunction = disabledPastAndTodayJob;
    job_type = "upcomming_job";
  } else if (activeFilterTab === 0 && activeTab === 2) {
    disabledDateFunction = disabledFutureDate;
    job_type = "past_job";
  } else if (activeFilterTab === 1) {
    disabledDateFunction = false;
  }

  return (
    <Container>
      <BreadcrumbWrap>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: <Link to={"/"}>Home</Link>,
            },
            {
              title: <Link to={"/user-management"}>User Management</Link>,
            },
            {
              title: "User Job Details",
            },
          ]}
        />
      </BreadcrumbWrap>
      <Wrapper>
        <TopSection>
          <Title>User Job Details</Title>
          <ButtonWrapper>
            <UpDateButton
              onClick={() => navigate(`/user-management/update/${id}`)}
            >
              <FaRegEdit size={15} />
              <ButtonText> Edit/Update</ButtonText>
            </UpDateButton>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={18} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <UserWrapper>
          <DetailsWrapper>
            <ImageWrapper>
              <Image src={data?.photo !== null ? data?.photo : UserIcon} />
            </ImageWrapper>
            <Details>
              <Wrapper1>
                <TextWrapper>
                  <Label>
                    <LuUser2 size={20} /> <div>Full Name</div>
                  </Label>
                  <Data>
                    {capitalizeFirstLetter(
                      data?.full_name ? data?.full_name : ""
                    )}
                  </Data>
                </TextWrapper>
                <TextWrapper>
                  <Label>
                    <LuPhone size={20} /> <div>Phone Number</div>
                  </Label>
                  <Data>{data?.phone}</Data>
                </TextWrapper>
                <TextWrapper>
                  <Label>
                    <CiMail size={20} /> <div>Email</div>
                  </Label>
                  <Data>{data?.email}</Data>
                </TextWrapper>
              </Wrapper1>
              <img style={{ width: "100%" }} src={seprator} alt="seprator" />
              <Wrapper1>
                <TextWrapper>
                  <Label>
                    <CiLocationOn size={20} /> <div>Address</div>
                  </Label>
                  <Data>
                    {data?.address1?.trim().length > 0
                      ? data?.address1
                      : data?.location}{" "}
                    {data?.address2?.trim().length > 0 ? data?.address2 : ""}
                  </Data>
                </TextWrapper>
                <TextWrapper>
                  <Label>
                    <LuBuilding2 size={20} /> <div>Company Name</div>
                  </Label>
                  <Data>{data?.company_name}</Data>
                </TextWrapper>
                <TextWrapper>
                  <Label>
                    <LuPhone size={20} /> <div>Company Number</div>
                  </Label>
                  <Data>{data?.company_number}</Data>
                </TextWrapper>
              </Wrapper1>
            </Details>
          </DetailsWrapper>
        </UserWrapper>

        <TabWrapper>
          {activeFilterTab !== 1 ? (
            <ButtonWrap>
              {tabdata?.map((item, index) => {
                return (
                  <TabButton
                    isActive={activeTab === index}
                    onClick={() => setActiveTab(index)}
                  >
                    {item}
                  </TabButton>
                );
              })}
            </ButtonWrap>
          ) : (
            <div></div>
          )}
          <ButtonWrap>
            {filterTab?.map((item, index) => {
              return (
                <TabButton
                  isActive={activeFilterTab === index}
                  onClick={() => setActiveFilterTab(index)}
                >
                  {item}
                </TabButton>
              );
            })}
          </ButtonWrap>
        </TabWrapper>
        {activeFilterTab !== 1 ? (
          items?.map((item, index) => {
            if (index === activeTab) {
              return item.children;
            }
          })
        ) : (
          <TableContainer
            title={"Unassigned Jobs"}
            colums={UnAssignedJob}
            onResetClick={onResetClick}
            data={tableData}
            setfilter={setfilter}
            filterValue={filterValue}
            filter={filter}
            type="user"
            filtertext={"Filter"}
            totalCount={totalCount}
            onPaginationChange={(page: any, pageSize: any) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
        )}
        {filter && (
          <CommonFilter
            filter={filter}
            handleOk={handleOk}
            handleCancel={handleCancel}
            DATE_FILTERS={DATE_FILTERS}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onSearchClick={onSearchClick}
            disabledDate={disabledDateFunction}
            job_type={job_type}
            isDisabled={filterValue.date_from === "" ? true : false}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default UserJobDetails;
