import {
  UserWrapper,
  DetailsWrapper,
  ImageWrapper,
  Details,
  Image,
  Wrapper1,
  TextWrapper,
  Label,
  Data,
} from "styles/commonStyle";
import driverImage2 from "assets/images/driverImage.png";
import seprator from "assets/images/seprator.png";
import { CiMail } from "react-icons/ci";
import { LuUser2 } from "react-icons/lu";
import { LuPhone } from "react-icons/lu";
import { CiLocationOn } from "react-icons/ci";
import calender from "assets/images/calender.png";
import gender from "assets/images/gender.png";
import { Icon } from "styles/pages/userManagement";
import moment from "moment";
import { useEffect, useState } from "react";
import { APP_BASE_URL } from "api/api.constant";
import { shortenAddress } from "constants/common";
import UserIcon from "../../assets/images/no-user.jpg";
import { capitalizeFirstLetter } from "components/commonFunction";
import { DriverDocuments } from "components/driverdocument";

interface DetailsPage {
  data: any;
}
export const DriverComponent: React.FC<DetailsPage> = ({ data }) => {
  return (
    <>
      <DetailsWrapper>
        <ImageWrapper>
          <Image src={data?.photo !== null ? data?.photo : UserIcon} />
        </ImageWrapper>
        <Details>
          <Wrapper1>
            <TextWrapper>
              <Label>
                <LuUser2 size={20} /> <div>Full Name</div>
              </Label>
              <Data>
                {capitalizeFirstLetter(data?.full_name ? data?.full_name : "")}
              </Data>
            </TextWrapper>
            <TextWrapper>
              <Label>
                <LuPhone size={20} /> <div>Phone Number</div>
              </Label>
              <Data>{data?.phone}</Data>
            </TextWrapper>
            <TextWrapper>
              <Label>
                <CiMail size={20} /> <div>Email</div>
              </Label>
              <Data>{data?.email}</Data>
            </TextWrapper>
          </Wrapper1>
          <img style={{ width: "100%" }} src={seprator} alt="seprator" />
          <Wrapper1>
            <TextWrapper>
              <Label>
                <CiLocationOn size={20} /> <div>Address</div>
              </Label>
              <Data>
                {data?.address1?.trim().length > 0
                  ? data?.address1
                  : data?.location}{" "}
                {data?.address2?.trim().length > 0 ? data?.address2 : ""}
              </Data>
            </TextWrapper>
            <TextWrapper>
              <Label>
                <Icon src={calender} className="custumImage" />
                <div>Date of Birth</div>
              </Label>
              <Data>
                {moment(data?.dob, "DD/MM/YYYY", true).isValid() ? (
                  <>{moment(data?.dob, "DD/MM/YYYY").format("DD-MM-YYYY")}</>
                ) : (
                  <> </>
                )}
              </Data>
            </TextWrapper>
            {/* <TextWrapper>
              <Label>
                <Icon src={gender} className="custumImage" /> <div>Gender</div>
              </Label>
              <Data>{data?.gender}</Data>
            </TextWrapper> */}
          </Wrapper1>
        </Details>
      </DetailsWrapper>

      {data?.extraDoc?.length > 0 && <DriverDocuments data={data?.extraDoc} />}
    </>
  );
};
