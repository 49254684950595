import {
  UserWrapper,
  DetailsWrapper,
  ImageWrapper,
  Details,
  Image,
  Wrapper1,
  TextWrapper,
  Label,
  Data,
  Wrapper2,
  AddressWrapper,
  AddressData,
} from "styles/commonStyle";
import userImage2 from "assets/images/userImage2.png";
import { CiMail } from "react-icons/ci";
import seprator from "assets/images/seprator.png";
import { LuBuilding2, LuUser2 } from "react-icons/lu";
import { LuPhone } from "react-icons/lu";
import { CiLocationOn } from "react-icons/ci";
import calender from "assets/images/calender.png";
import gender from "assets/images/gender.png";
import { Icon } from "styles/pages/userManagement";
import { APP_BASE_URL } from "api/api.constant";
import { shortenAddress } from "constants/common";
import UserIcon from "../../assets/images/no-user.jpg";
import { capitalizeFirstLetter } from "components/commonFunction";

export const UserComponent = ({ data }: any) => {
  return (
    <DetailsWrapper>
      <ImageWrapper>
        <Image src={data?.photo ? data?.photo : UserIcon} />
      </ImageWrapper>
      <Details>
        <Wrapper1>
          <TextWrapper>
            <Label>
              <LuUser2 size={20} /> <div>Full Name</div>
            </Label>
            <Data>
              {capitalizeFirstLetter(data?.full_name ? data?.full_name : "")}
            </Data>
          </TextWrapper>
          <TextWrapper>
            <Label>
              <LuPhone size={20} /> <div>Phone Number</div>
            </Label>
            <Data>{data?.phone}</Data>
          </TextWrapper>
          <TextWrapper>
            <Label>
              <CiMail size={20} /> <div>Email</div>
            </Label>
            <Data>{data?.email}</Data>
          </TextWrapper>
        </Wrapper1>
        <img style={{ width: "100%" }} src={seprator} alt="seprator" />
        <Wrapper2>
          <AddressWrapper>
            <Label>
              <CiLocationOn size={20} /> <div>Address</div>
            </Label>
            <AddressData>
              {data?.address1?.trim().length > 0
                ? data?.address1
                : data?.location}
              {data?.address2?.trim().length > 0 ? data?.address2 : ""}
            </AddressData>
          </AddressWrapper>
          {data?.company_name && (
            <TextWrapper>
              <Label>
                <LuBuilding2 size={20} />
                <div>Company Name</div>
              </Label>
              <Data>{data?.company_name}</Data>
            </TextWrapper>
          )}
          {data?.company_number && (
            <TextWrapper>
              <Label>
                <LuPhone size={20} /> <div>Company Number</div>
              </Label>
              <Data>{data?.company_number}</Data>
            </TextWrapper>
          )}
        </Wrapper2>
      </Details>
    </DetailsWrapper>
  );
};
