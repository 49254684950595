import {
  AmountData,
  DriverData,
  JobsData,
  UserData,
} from "constants/dashboardData";
import {
  Container,
  Title,
  AmountWrapper,
  CardWrapper,
  Card,
  Icone,
  CardTitle,
  Ammount,
  UserWrapper,
  ChartWrappeer,
  TableWrapper,
  PieChartWrapper,
  TableTitle,
  ChartHints,
  Quater1,
  Quater2,
  Quater3,
  Quater4,
  BarChartWrappeer,
  SuggetionWrapper,
  FilterWrapper,
  BarWrapper,
  BarColor,
  RightContent,
  PieTitle,
  BreadcrumbWrap,
  Action,
  UserNameWrapper,
  StatusButton,
} from "styles/dashboard";
import { Breadcrumb, Dropdown, MenuProps, Table } from "antd";
import Example from "components/graph";
import { PieChartComponent } from "components/pieChart";
import { DataType, data } from "constants/tableData";
import Breadcrumbs from "components/Breadcrumbs";
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { number } from "yup";
import { APIS } from "api/api.constant";
import { postApi } from "api/api.client";
import { ListingStatusColumn } from "components";
import { ColumnsType } from "antd/es/table";
import { TOAST_MESSAGE } from "constants/common";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";

import { UserImage } from "styles/dashboard";
import { getToast } from "utils";
import { capitalizeFirstLetter } from "components/commonFunction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState<string | null>(
    moment().year().toString()
  );
  const [refresh, setRefresh] = useState(false);
  const [barchartdata, setEarnignBarchartData] = useState<any>(null);

  const [earningdata, setEarnignData] = useState<any>(AmountData);
  const [userregisterdata, setUserRegisterData] = useState<any>(UserData);
  const [userfivedata, setUserFiveData] = useState<any>([]);
  const [userpiechartdata, setUserPieChartData] = useState<any>([]);
  const [driverregisterdata, setDriverRegisterData] = useState<any>(DriverData);
  const [driverfivedata, setDriverFiveData] = useState<any>([]);
  const [driverpiechartdata, setDriverPieChartData] = useState<any>([]);
  const [jobdata, setJOBData] = useState<any>(JobsData);
  const [latestjobdata, setLatestJobData] = useState<any>([]);
  const [jobpiechartdata, setJobPieChartData] = useState<any>([]);

  const apiCall1 = () =>
    postApi(APIS.EARNING_BARCHART, {
      year: selectedValue,
    })
      .then((res) => {
        let response: any = res;
        setEarnignBarchartData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall2 = () =>
    postApi(APIS.EARNING_DATA, {})
      .then((res) => {
        let response: any = res;
        const transformedData = [
          {
            key: "1",
            earned: Number(
              response?.data?.totalEarning?.totalAmount || 0
            ).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            }),
          },
          {
            key: "2",
            earned: Number(
              response?.data?.totalEarningToday?.todayAmount || 0
            ).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            }),
          },
          {
            key: "3",
            earned: Number(
              response?.data?.totalEarningThisWeek?.weekAmount || 0
            ).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            }),
          },
          {
            key: "4",
            earned: Number(
              response?.data?.totalEarningThisMonth?.monthAmount || 0
            ).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            }),
          },
          {
            key: "5",
            earned: Number(
              response?.data?.totalEarningThisYear?.yearAmount || 0
            ).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            }),
          },
        ];
        setEarnignData(transformedData);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall3 = () =>
    postApi(APIS.USER_REGISTERED, { flagType: "user" })
      .then((res) => {
        let response: any = res;
        let transformedUserData = [
          {
            key: "1",
            earned: response?.data?.total || 0,
          },
          {
            key: "2",
            earned: response?.data?.today || 0,
          },
          {
            key: "3",
            earned: response?.data?.thisWeek || 0,
          },
          {
            key: "4",
            earned: response?.data?.thisMonth || 0,
          },
          {
            key: "5",
            earned: response?.data?.thisYear || 0,
          },
        ];
        setUserRegisterData(transformedUserData);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall4 = () =>
    postApi(APIS.LAST_FIVE_YEAR_DATA, {
      flagType: "user",
    })
      .then((res) => {
        let response: any = res;
        setUserFiveData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall5 = () =>
    postApi(APIS.PIE_CHART, {
      flagType: "user",
    })
      .then((res) => {
        let response: any = res;
        setUserPieChartData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall6 = () =>
    postApi(APIS.USER_REGISTERED, { flagType: "driver" })
      .then((res) => {
        let response: any = res;
        let transformedUserData = [
          {
            key: "1",
            earned: response?.data?.total || 0,
          },
          {
            key: "2",
            earned: response?.data?.today || 0,
          },
          {
            key: "3",
            earned: response?.data?.thisWeek || 0,
          },
          {
            key: "4",
            earned: response?.data?.thisMonth || 0,
          },
          {
            key: "5",
            earned: response?.data?.thisYear || 0,
          },
        ];
        setDriverRegisterData(transformedUserData);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall7 = () =>
    postApi(APIS.LAST_FIVE_YEAR_DATA, {
      flagType: "driver",
    })
      .then((res) => {
        let response: any = res;
        setDriverFiveData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall8 = () =>
    postApi(APIS.PIE_CHART, {
      flagType: "driver",
    })
      .then((res) => {
        let response: any = res;
        setDriverPieChartData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});
  const apiCall9 = () =>
    postApi(APIS.JOB_DATA, {})
      .then((res) => {
        let response: any = res;

        let transformedUserData = [
          {
            key: "1",
            earned: response?.data?.totalJobs || 0,
          },
          {
            key: "2",
            earned: response?.data?.totalJobsToday || 0,
          },
          {
            key: "3",
            earned: response?.data?.totalJobsThisWeek || 0,
          },
          {
            key: "4",
            earned: response?.data?.totalJobsThisMonth || 0,
          },
          {
            key: "5",
            earned: response?.data?.totalJobsThisYear || 0,
          },
        ];

        setJOBData(transformedUserData);
      })
      .catch((err) => {})
      .finally(() => {});

  const apiCall10 = () =>
    postApi(APIS.LATEST_JOB, {})
      .then((res) => {
        let response: any = res;
        setLatestJobData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});

  const apiCall11 = () =>
    postApi(APIS.JOB_PIE_CHART, {
      flagType: "driver",
    })
      .then((res) => {
        let response: any = res;
        setJobPieChartData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(toggleLoader({ loader: true }));
        await Promise.any([
          apiCall1(),
          apiCall2(),
          apiCall3(),
          apiCall4(),
          apiCall5(),
          apiCall6(),
          apiCall7(),
          apiCall8(),
          apiCall9(),
          apiCall10(),
          apiCall11(),
        ]);
      } catch (err) {
        console.log("All API calls failed");
      } finally {
        dispatch(toggleLoader({ loader: false }));
      }
    };

    fetchData();
  }, [selectedValue, refresh]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div onClick={() => selectYear(moment().year().toString())}>
          {moment().year()}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() =>
            selectYear(moment().subtract(1, "year").year().toString())
          }
        >
          {moment().subtract(1, "year").year()}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() =>
            selectYear(moment().subtract(2, "year").year().toString())
          }
        >
          {moment().subtract(2, "year").year()}
        </div>
      ),
    },
  ];

  const onStatusChange = (event: any, key: any, id: any) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(key === "block" ? APIS.UN_BLOCK_USER : APIS.BLOCK_USER, {
      userId: id,
    })
      .then((res) => {
        getToast("success", TOAST_MESSAGE.STATUS_CHANGED);
      })
      .catch((err) => {})
      .finally(() => {
        setRefresh(!refresh);
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      key: "name",
      render: (_, record) => (
        <UserNameWrapper>
          <UserImage
            src={
              record?.photo
                ? record?.photo
                : "https://i.pinimg.com/originals/fa/ad/a7/faada775710b6c45762a0fa9c6c9e587.jpg"
            }
          />
          {capitalizeFirstLetter(record?.full_name)}
        </UserNameWrapper>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Reg. Date",
      dataIndex: "createdAt",
      key: "regdate",
      render: (_, record: any) => {
        return <>{moment(record?.createdAt).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: <Action>Status</Action>,
      key: "isBlocked",

      render: (_, record: any) => {
        return (
          <ListingStatusColumn
            record={record}
            onStatusChange={onStatusChange}
          />
        );
      },
    },
  ];

  const Drivercolumns: ColumnsType<DataType> = [
    {
      title: "Driver Name",
      key: "name",
      render: (_, record) => (
        <UserNameWrapper>
          <UserImage
            src={
              record?.photo
                ? record?.photo
                : "https://i.pinimg.com/originals/fa/ad/a7/faada775710b6c45762a0fa9c6c9e587.jpg"
            }
          />
          {capitalizeFirstLetter(record?.full_name)}
        </UserNameWrapper>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Reg. Date",
      dataIndex: "createdAt",
      key: "regdate",
      render: (_, record: any) => {
        return <>{moment(record?.createdAt).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: <Action>Status</Action>,
      key: "isBlocked",
      render: (_, record: any) => {
        return (
          <ListingStatusColumn
            record={record}
            onStatusChange={onStatusChange}
          />
        );
      },
    },
  ];

  const JobColumns: ColumnsType<DataType> = [...columns];
  JobColumns.splice(3, 1, {
    title: <Action>Status</Action>,
    key: "status",

    render: (_, record: any) => {
      return (
        <Action>
          {" "}
          <StatusButton>{record.status}</StatusButton>
        </Action>
      );
    },
  });

  const selectYear = (value: string) => {
    console.log(value);
    setSelectedValue(value);
  };

  return (
    <Container>
      <BreadcrumbWrap>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: <Link to={"/"}>Home</Link>,
            },
            {
              title: <Link to={"/"}>Dashboard</Link>,
            },
          ]}
        />
      </BreadcrumbWrap>

      <AmountWrapper>
        <Title>Amount Earned</Title>
        <CardWrapper>
          {earningdata?.length > 0 &&
            earningdata?.map((item: any, index: any) => (
              <Card key={index}>
                <Icone src={AmountData[index]?.icone} />
                <CardTitle>{AmountData[index]?.title}</CardTitle>
                <Ammount>{item?.earned}</Ammount>
              </Card>
            ))}
        </CardWrapper>
        <BarChartWrappeer>
          <SuggetionWrapper>
            <TableTitle>
              Total Amount Earned{" "}
              {Number(barchartdata?.totalAmount || 0)?.toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP",
              })}
            </TableTitle>
            <RightContent>
              <BarWrapper>
                <BarColor />
                Earned
              </BarWrapper>
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                className="customeDrop"
              >
                <FilterWrapper>{selectedValue}</FilterWrapper>
              </Dropdown>
            </RightContent>
          </SuggetionWrapper>
          <Example data={barchartdata?.monthlyEarnings} />
        </BarChartWrappeer>
      </AmountWrapper>
      <UserWrapper>
        <Title>User Details</Title>
        <CardWrapper>
          {/* {UserData?.map((item) => ( */}
          {userregisterdata?.length > 0 &&
            userregisterdata?.map((item: any, index: any) => (
              <Card>
                <Icone src={UserData[index]?.icone} />
                <CardTitle>{UserData[index]?.title}</CardTitle>
                <Ammount>{item?.earned}</Ammount>
              </Card>
            ))}
        </CardWrapper>
        <ChartWrappeer>
          <TableWrapper>
            <TableTitle>5 most recent user registered</TableTitle>
            <Table
              className="tableStyle"
              columns={columns}
              dataSource={userfivedata}
              pagination={false}
            />
          </TableWrapper>
          <PieChartWrapper>
            <PieTitle>Users registered in last 4 quarters</PieTitle>
            <PieChartComponent
              data={userpiechartdata}
              title={"Total User Registered"}
            />
            <ChartHints>
              <Quater1>Quater1</Quater1>
              <Quater2>Quater2</Quater2>
              <Quater3>Quater3</Quater3>
              <Quater4>Quater4</Quater4>
            </ChartHints>
          </PieChartWrapper>
        </ChartWrappeer>
      </UserWrapper>
      <UserWrapper>
        <Title>Driver Details</Title>
        <CardWrapper>
          {driverregisterdata?.length > 0 &&
            driverregisterdata?.map((item: any, index: any) => (
              <Card>
                <Icone src={DriverData[index]?.icone} />
                <CardTitle>{DriverData[index]?.title}</CardTitle>
                <Ammount>{item?.earned}</Ammount>
              </Card>
            ))}
        </CardWrapper>
        <ChartWrappeer>
          <TableWrapper>
            <TableTitle>5 most recent Driver registered</TableTitle>
            <Table
              className="tableStyle"
              columns={Drivercolumns}
              dataSource={driverfivedata}
              pagination={false}
            />
          </TableWrapper>
          <PieChartWrapper>
            <PieTitle>Driver registered in last 4 quarters</PieTitle>
            <PieChartComponent
              data={driverpiechartdata}
              title={"Total Driver Registered"}
            />
            <ChartHints>
              <Quater1>Quater1</Quater1>
              <Quater2>Quater2</Quater2>
              <Quater3>Quater3</Quater3>
              <Quater4>Quater4</Quater4>
            </ChartHints>
          </PieChartWrapper>
        </ChartWrappeer>
      </UserWrapper>
      <UserWrapper>
        <Title>Jobs Details</Title>
        <CardWrapper>
          {jobdata?.length > 0 &&
            jobdata?.map((item: any, index: any) => (
              <Card>
                <Icone src={JobsData[index]?.icone} />
                <CardTitle>{JobsData[index]?.title}</CardTitle>
                <Ammount>{item?.earned}</Ammount>
              </Card>
            ))}
        </CardWrapper>
        <ChartWrappeer>
          <TableWrapper>
            <TableTitle>5 most recent Jobs done</TableTitle>
            <Table
              className="tableStyle"
              columns={JobColumns}
              dataSource={latestjobdata}
              pagination={false}
            />
          </TableWrapper>
          <PieChartWrapper>
            <PieTitle>Jobs done in last 4 quarters</PieTitle>
            <PieChartComponent
              data={jobpiechartdata}
              title={"Total Job Registered"}
            />
            <ChartHints>
              <Quater1>Quater1</Quater1>
              <Quater2>Quater2</Quater2>
              <Quater3>Quater3</Quater3>
              <Quater4>Quater4</Quater4>
            </ChartHints>
          </PieChartWrapper>
        </ChartWrappeer>
      </UserWrapper>
    </Container>
  );
};

export default Dashboard;
