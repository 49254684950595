import {
  LocationWrapper,
  TableLocatoinTitle,
  NoteWrapper,
} from "styles/unAssignedJob";
import { useEffect } from "react";

export const JobNote = ({ data, type }: any) => {
  // Separate pickup and drop-off data

  return (
    <>
      <TableLocatoinTitle className="doc">{type}</TableLocatoinTitle>
      <LocationWrapper>
        <NoteWrapper>{data}</NoteWrapper>
      </LocationWrapper>
    </>
  );
};
