import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import { CSM_SLUG_TYPE } from "constants/common";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Editor } from "styles/pages/setting";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const MobileServiceAgreement = () => {
  // const [data, setData] = useState("");

  return (
    <Container>
      <Wrapper>
        {/* <Title>Transport Your Goods Around the World. </Title>
          <Content dangerouslySetInnerHTML={{ __html: data }}></Content> */}
        <div className="section">
          <h2>Service Agreement for Kindra Cargo Ltd Courier App</h2>

          <h3>Non-Solicitation and Non-Poaching Clause</h3>
          <p>1.1 Non-Solicitation of Customers</p>
          <p>
            Self-employed drivers ("Drivers") agree not to solicit, contact, or
            enter into any direct business relationship with customers ("Users")
            they encounter through the Kindra Cargo Ltd ("the Company") app
            ("the App").
          </p>
          <p>This includes, but is not limited to:</p>
          <ul>
            <li>
              A. Providing personal contact information to Users for the purpose
              of bypassing the App
            </li>
            <li>
              B. Offering services outside of the App that would compete with
              the Company's services
            </li>
            <li>
              C. Accepting jobs or payments directly from Users without going
              through the App
            </li>
          </ul>

          <p>1.2 Non-Poaching</p>
          <p>
            Drivers agree not to engage in any poaching practices that may harm
            the Company's interests, including:
          </p>
          <ul>
            <li>
              A. Using information obtained through the App to establish a
              competing service within England, Scotland and Wales
            </li>
            <li>B. Encouraging Users to use alternative delivery services</li>
            <li>
              C. Participating in or promoting any service that competes
              directly with the Company's offerings
            </li>
          </ul>

          <p>1.3 User Agreement</p>
          <p>
            Users agree not to solicit Drivers for services outside of the App
            or attempt to broker deals directly with Drivers for cheaper rates,
            bypassing the Company's App and services.
          </p>

          <p>1.4 Duration</p>
          <p>
            These non-solicitation and non-poaching obligations shall remain in
            effect during the Driver's or User's relationship with the Company
            and for a period of 12 months following the termination of said
            relationship.
          </p>

          <p>1.5 Enforcement</p>
          <p>
            The Company reserves the right to take legal action and seek damages
            for any breach of these non-solicitation and non-poaching
            provisions. Before initiating legal action, the Company will attempt
            to resolve the issue through mediation, to be initiated within 30
            days of discovering the breach.
          </p>

          <h3>Description of Services</h3>
          <p>2.1 App Functionality</p>
          <p>
            Kindra Cargo Ltd provides a mobile application that connects
            self-employed courier Drivers with Users requiring bulk delivery
            services.
          </p>

          <p>2.2 Delivery Options</p>
          <ul>
            <li>
              A. Direct Delivery: Upon collection, the Driver will aim to
              deliver the load directly to the destination without any
              intermediate stops.
            </li>
            <li>
              B. Shared Loading: In some cases, the Driver may combine smaller
              loads (loads weighing less than 700 kg) going in the same
              direction to optimise efficiency. The App will notify Users of any
              such arrangements, specifying the estimated delivery time.
            </li>
            <li>
              C. Return Loading: In other cases, the Driver may be returning
              home in the same direction to optimise efficiency and reduce
              costs. The App will notify Users of such arrangements and the
              estimated delivery time.
            </li>
          </ul>

          <p>2.3 Collection Timelines</p>
          <p>Users can choose from the following collection options:</p>
          <ul>
            <li>A. ASAP: Driver will collect as soon as possible.</li>
            <li>
              B. Before [specific time]: Driver will collect before the
              specified time.
            </li>
            <li>
              C. Between [time range]: Driver will collect within the specified
              time range.
            </li>
          </ul>

          <p>2.4 Delivery Timelines</p>
          <p>
            Most deliveries are same-day, direct deliveries. In cases of shared
            loading (refer to Section 2.2), deliveries may take longer due to
            the additional stops required to deliver other loads. Drivers are
            not expected to keep stock in their vehicles overnight unless
            explicitly agreed upon with the Company and the User. If overnight
            storage is required due to unforeseen circumstances, such as delays
            or missed delivery windows, the Driver must update both the Company
            and the User through the App. The Company and User must approve any
            such arrangements to ensure the security and integrity of the goods.
          </p>

          <h3>Terms and Conditions</h3>
          <p>3.1 Use of the App</p>
          <p>
            Users and Drivers must create an account to use the App. All users
            must be at least 18 years of age. Users and Drivers are responsible
            for maintaining the confidentiality of their account information.
          </p>

          <p>3.2 Driver Requirements</p>
          <p>Drivers are responsible for:</p>
          <ul>
            <li>
              A. Maintaining valid and appropriate insurance for their vehicle
              and cargo.
            </li>
            <li>
              B. Ensuring their vehicle meets all road regulations and safety
              standards.
            </li>
            <li>
              C. Possessing a valid driver's licence and any necessary permits
              for commercial delivery services.
            </li>
            <li>D. Maintaining their vehicle in good working condition.</li>
            <li>E. Complying with all traffic laws and regulations.</li>
          </ul>

          <p>3.3 Delivery Process</p>
          <ul>
            <li>
              A. Users must provide accurate and complete delivery information.
            </li>
            <li>
              B. Drivers must follow the delivery instructions provided through
              the App.
            </li>
            <li>
              C. Drivers must update the App with real-time status changes
              (e.g., collected, in transit, delivered).
            </li>
          </ul>

          <p>3.4 Liability</p>
          <p>
            The Company's liability is limited to direct damages caused by gross
            negligence or willful misconduct. The Company is not liable for
            indirect, incidental, consequential, or punitive damages. The total
            liability of the Company for any claim arising out of or relating to
            this agreement shall not exceed the amount paid by the User for the
            specific delivery in question. This limitation of liability does not
            apply to damages resulting from death or personal injury caused by
            the Company's negligence, or any other liability that cannot be
            excluded or limited by English law.
          </p>

          <p>3.5 Cancellation Policy</p>
          <p>
            Users may cancel a delivery request before a Driver accepts the job
            at no cost. Cancellations after a Driver accepts the job will incur
            a cancellation fee calculated based on the following factors:
          </p>
          <ul>
            <li>Server and API operational costs: £20</li>
            <li>
              Driver's estimated fuel cost for the cancelled trip: [Variable]
            </li>
            <li>Administrative time for processing the cancellation: £20</li>
            <li>
              Opportunity cost for the Driver: [Variable, not to exceed £20]
            </li>
          </ul>
          <p>
            The total cancellation fee shall not exceed £60. This fee represents
            a genuine pre-estimate of the loss incurred by the Company and the
            Driver due to the cancellation.
          </p>

          <p>3.6 Refund Policy</p>
          <p>
            Refund requests must be submitted within 31 days of the scheduled
            delivery date. Refunds may be issued for failed deliveries due to
            Driver error, which includes:
          </p>
          <ul>
            <li>
              Failure to collect or deliver within the agreed-upon timeframe
              without a reasonable explanation, as determined by the Company.
            </li>
            <li>Damage to goods due to improper handling.</li>
            <li>
              Delivery to the wrong address despite correct information provided
              by the User.
            </li>
          </ul>
          <p>
            The Company reserves the right to investigate all refund requests
            before approval. Refunds will not be issued for circumstances beyond
            the Company's or Driver's control, such as traffic delays, severe
            weather, or incorrect information provided by the User.
          </p>

          <h3>Pricing and Payment Terms</h3>
          <p>4.1 Pricing Structure</p>
          <ul>
            <li>Minimum Charge: £66 for any delivery up to 15 miles.</li>
            <li>
              Dynamic Price Per Mile (PPM) calculation for distances over 15
              miles: The price per mile decreases incrementally based on the
              vehicle type and distance travelled. Detailed pricing information
              will be available in the App.
            </li>
            <li>
              Company Commission: 10% of the total fare, added to the User's
              price.
            </li>
          </ul>

          <p>4.2 Payment Conditions for Users</p>
          <p>
            Users may choose to pay for the delivery service at the time of
            booking through the App or opt for a regular billing cycle. Regular
            billing cycles may be weekly, monthly, or bi-monthly, as agreed
            between the User and the Company. Payments not received within two
            months of the invoice date will incur a late payment fee.
          </p>

          <p>4.3 Late Payment Fees and Resolution Process</p>
          <p>
            Payments not received within two months of the invoice date shall
            incur a late payment fee of £60. For each additional month that the
            payment remains outstanding, an additional fee of £30 shall be
            charged. Before imposing late payment fees, the Company will attempt
            to contact the User to discuss the reason for the late payment and
            offer potential solutions.
          </p>
          <p>
            The Company offers the following payment plan options for Users
            experiencing financial difficulties:
          </p>
          <ul>
            <li>
              a) Bi-weekly payments: The outstanding amount can be split into
              equal bi-weekly payments over a period of up to 3 months.
            </li>
            <li>
              b) Monthly payments: The outstanding amount can be split into
              equal monthly payments over a period of up to 6 months.
            </li>
            <li>
              c) Custom plan: In exceptional circumstances, a custom payment
              plan may be negotiated.
            </li>
          </ul>
          <p>To enter into a payment plan, Users must:</p>
          <ul>
            <li>
              Contact the Company within 14 days of receiving the late payment
              notice.
            </li>
            <li>Provide a valid reason for the payment delay.</li>
            <li>Agree to the terms of the payment plan in writing.</li>
            <li>
              Make the first payment as per the agreed plan within 7 days of the
              plan being accepted.
            </li>
          </ul>
          <p>
            Failure to adhere to the agreed payment plan will result in the
            immediate reinstatement of late payment fees and potential legal
            action. The Company reserves the right to suspend services if
            payments are not made within the agreed timeframe. If all attempts
            at resolution fail, the Company may pursue legal action to recover
            outstanding debts, including but not limited to issuing formal
            demand letters, engaging debt collection agencies, or initiating
            legal proceedings to obtain a judgment for the unpaid amounts.
          </p>

          <h3>Additional Charges</h3>
          <ul>
            <li>
              Waiting Time: Drivers may request additional fees for excessive
              waiting time when completing the job and obtaining the User's
              signature.
            </li>
            <li>
              Other Extra Fees: Any additional fees must be agreed upon between
              the Driver and User through the App prior to the service being
              rendered.
            </li>
            <li>
              Overloading Vehicles: Users shall not request the transportation
              of loads exceeding the legal weight limit for the vehicle type.
              Drivers are responsible for ensuring their vehicle is not
              overloaded. If a load is found to exceed the legal limit, the
              delivery will be refused or rescheduled with additional charges.
            </li>
            <li>
              Hazardous Materials: Users must declare any hazardous materials at
              the time of booking. Transportation of hazardous materials is
              subject to additional charges and requires appropriate licensing
              and safety measures. Undeclared hazardous materials may result in
              cancellation of the delivery and additional fees.
            </li>
            <li>
              Improper Packaging: Users are responsible for adequately packaging
              their items for delivery. Should items be improperly packaged and
              cause damage to the Driver's vehicle or other items, the User
              shall be liable for any repair or replacement costs.
            </li>
            <li>
              Unauthorized Passengers: Drivers are strictly prohibited from
              transporting passengers during deliveries. Any violation of this
              policy may result in immediate termination of the Driver's
              contract.
            </li>
            <li>
              Restricted Items: Users must not request the transportation of
              illegal items or items requiring special permits without prior
              arrangement and approval from the Company. Attempts to transport
              such items may result in cancellation of the delivery, additional
              fees, and potential reporting to relevant authorities.
            </li>
          </ul>

          <h3>Payment Conditions for Drivers</h3>
          <p>
            Drivers are required to submit monthly invoices to the Company for
            services rendered. The Company will aim to process and pay the
            invoices within 31 days of receipt, with a maximum leeway of up to
            61 days. Payments will be made to the bank account specified by the
            Driver in their account details.
          </p>

          <h3>Acceptance of Terms</h3>
          <p>
            By using the App, both Users and Drivers acknowledge that they have
            read, understood, and agree to be bound by the terms and conditions
            outlined in this Service Agreement. A mandatory check-box or
            electronic signature within the App ensures explicit agreement to
            the terms.
          </p>

          <h3>Termination</h3>
          <p>
            Either party may terminate this agreement by providing 30 days'
            written notice. The Company may terminate the agreement immediately
            in cases of material breach of contract, including but not limited
            to: repeated late payments, violation of non-solicitation or
            non-poaching clauses, intentional damage to property, fraudulent
            activity, or any illegal activities.
          </p>

          <h3>Dispute Resolution</h3>
          <p>
            Any disputes arising out of or related to this agreement shall be
            resolved through mediation before resorting to any legal action. If
            mediation fails, the parties agree to resolve the dispute through
            binding arbitration under the rules of [specified arbitration body].
          </p>

          <h3>Governing Law and Jurisdiction</h3>
          <p>
            This agreement and any dispute or claim arising out of or in
            connection with it or its subject matter or formation (including
            non-contractual disputes or claims) shall be governed by and
            construed in accordance with the law of England and Wales. Each
            party irrevocably agrees that the courts of England and Wales shall
            have exclusive jurisdiction to settle any dispute or claim arising
            out of or in connection with this agreement or its subject matter or
            formation (including non-contractual disputes or claims).
          </p>

          <h3>Updates to Terms</h3>
          <p>
            The Company reserves the right to update these terms every 6 months.
            Users and Drivers will be notified of any changes via email and
            through the App. In the event of a serious issue or flaw being
            detected, the Company may update the terms immediately, with notice
            being provided as soon as practicable. Continued use of the App
            after such changes constitutes acceptance of the new terms.
          </p>

          <h3>Force Majeure</h3>
          <p>
            Neither party shall be in breach of this agreement nor liable for
            delay in performing, or failure to perform, any of its obligations
            under this agreement if such delay or failure result from events,
            circumstances or causes beyond its reasonable control. In such
            circumstances, the affected party shall be entitled to a reasonable
            extension of the time for performing such obligations. If the period
            of delay or non-performance continues for 3 months, the party not
            affected may terminate this agreement by giving 30 days' written
            notice to the affected party.
          </p>

          <h3>Intellectual Property</h3>
          <p>
            All intellectual property rights in the App, including but not
            limited to software, designs, logos, and content, are owned by or
            licensed to the Company. Users and Drivers are granted a limited,
            non-exclusive, non-transferable license to use the App solely for
            the purposes outlined in this agreement. No other rights are
            granted, and Users and Drivers shall not copy, modify, distribute,
            sell or lease any part of the App or its content.
          </p>
        </div>
      </Wrapper>
    </Container>
  );
};
