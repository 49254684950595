import {
  LocationDetails,
  LocationWrapper,
  DetailCard,
  Head,
  Table,
  TableSpacing,
  TableRow,
  TableCell,
  TableCellLocation,
  TableLocatoinTitle,
} from "styles/unAssignedJob";

import {
  formatDropupCounts,
  formatPickupCounts,
} from "components/commonFunction";

export const LocationTable = ({ locationsData, jobDropOffData }: any) => {
  // const shortenLocation = (location: string) => {
  //   const maxLength = 22;
  //   return location?.length > maxLength
  //     ? `${location?.substring(0, maxLength)}...`
  //     : location;
  // };

  // const details = [
  //   { key: "Address", label: "Address" },
  //   { key: "Items", label: "Items" },
  // ];

  return (
    <>
      <TableLocatoinTitle className="doc">
        Location Information
      </TableLocatoinTitle>
      <LocationWrapper>
        <LocationDetails>
          <DetailCard>
            <Head>Pickup Information</Head>
            <Table>
              <TableRow>
                <TableCellLocation>Address</TableCellLocation>
                <TableCellLocation>Items </TableCellLocation>
              </TableRow>
              {locationsData.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCellLocation>
                    {item.location ||
                      item?.addressLine1 + " " + item.addressLine2}
                  </TableCellLocation>
                  <TableCellLocation>
                    <TableSpacing>{formatPickupCounts(item)}</TableSpacing>
                  </TableCellLocation>
                </TableRow>
              ))}
            </Table>
          </DetailCard>
        </LocationDetails>
        <LocationDetails>
          <DetailCard>
            <Head>Drop Off Information</Head>
            <Table>
              <TableRow>
                <TableCellLocation>Address</TableCellLocation>
                <TableCellLocation>Items</TableCellLocation>
              </TableRow>
              {jobDropOffData.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCellLocation>
                    {item.drop_off_location ||
                      item?.addressLine1 + " " + item.addressLine2}
                  </TableCellLocation>
                  <TableCellLocation>
                    <TableSpacing>{formatDropupCounts(item)} </TableSpacing>
                  </TableCellLocation>
                </TableRow>
              ))}
            </Table>
          </DetailCard>
        </LocationDetails>
      </LocationWrapper>
    </>
  );
};
