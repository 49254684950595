import moment from "moment";

export const monthOptions = [
  { value: moment().format("MMMM"), label: moment().format("MMMM") },
  // { value: "February", label: "February" },
  // { value: "March", label: "March" },
  // { value: "April", label: "April" },
  // { value: "May", label: "May" },
  // { value: "June", label: "June" },
  // { value: "July", label: "July" },
  // { value: "August", label: "August" },
  // { value: "September", label: "September" },
  // { value: "October", label: "October" },
  // { value: "November", label: "November" },
  // { value: "December", label: "December" },
];
