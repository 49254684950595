import Navbar from "components/navbar";
import { useLocation } from "react-router-dom";
import { Editor } from "styles/pages/setting";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const AboutUsView = () => {
  const location = useLocation();
  const { payload } = location.state || {};
  return (
    <>
      <Container>
        {/* <Navbar classname={"customeNav"} /> */}
        <Wrapper>
          <Title>Transport Your Goods Around the World. </Title>

          <Content dangerouslySetInnerHTML={{ __html: payload }}></Content>
        </Wrapper>
      </Container>
    </>
  );
};
