import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import seprator from "assets/images/seprator.png";
import truck2 from "assets/images/truck2.png";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NameWrap, NameText, InnerContainer } from "styles/jobManagement";
import { DriverComponent } from "components/driverDetails";
import customerImage from "assets/images/customerImage.png";
import { FaTruckFast } from "react-icons/fa6";
import { Rating, RatingWrap } from "styles/driverCompletedJob";
import { Rate } from "antd";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import truck from "assets/images/truck.png";
import { Line2, Seprators, TrackDetails } from "styles/pages/hotshotManagement";
import { postApi } from "api/api.client";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import { APIS, color, jobDropDetails, locationDetails } from "api/api.constant";
import moment from "moment";
import { JobNote } from "components/jobNote";
import { LocationHotshotTable } from "components/locationHotshotTable";
import {
  getLocation,
  hotShotDropOffText,
  hotShotPickOffText,
} from "components/commonFunction";
import { HotShotSignature } from "components/hotshotsignature";
const AssignedHotShot = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.HOTSHORT_DETAIL}`, { id: id })
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: data?.userHotSot?.pickup_item_count },
    {
      label: "Amount",
      data: `${Number(data?.amount || 0)?.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      })}`,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Assigned Hotshots Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <InnerContainer>
          <UserWrapper>
            <NameWrap>
              <NameText>Driver Detail</NameText>
            </NameWrap>
            <DriverComponent data={data?.driver} />
            <VahicleWrapper>
              {vehicleData.map(({ label, data }) => (
                <TextWrapper>
                  <Label>
                    <div>{label}</div>
                  </Label>
                  <DataWrapper>{data}</DataWrapper>
                </TextWrapper>
              ))}
            </VahicleWrapper>
            <Seprators src={seprator} />

            <TrackDetails>
              <TractLine className="trackline">
                <Place>
                  {data?.userHotSot?.user_pickup_location ||
                    data?.userHotSot?.user_pickup_address_1 +
                      " " +
                      data?.userHotSot?.user_pickup_address_2 +
                      " " +
                      data?.userHotSot?.user_pickup_city +
                      " " +
                      data?.userHotSot?.user_pickup_state +
                      " " +
                      data?.userHotSot?.user_pickup_zip}
                </Place>
                <Line2 color="#F7931E" />
                <IconWrap>
                  <img src={truck} alt="truck" width={50} height={50} />
                </IconWrap>
                <Place>
                  {data?.userHotSot?.user_dopoff_location ||
                    data?.userHotSot?.user_dopoff_address_1 +
                      " " +
                      data?.userHotSot?.user_dopoff_address_2 +
                      " " +
                      data?.userHotSot?.user_dopoff_city +
                      " " +
                      data?.userHotSot?.user_dopoff_state +
                      " " +
                      data?.userHotSot?.user_dopoff_zip}
                </Place>
              </TractLine>
            </TrackDetails>
            <Seprators src={seprator} />
          </UserWrapper>

          <UserWrapper>
            <NameWrap>
              <NameText>User Details</NameText>
            </NameWrap>
            <UserComponent data={data?.users} />
            {data?.userHotSot?.notes?.length > 0 && (
              <JobNote type="Note" data={data?.userHotSot?.notes} />
            )}
            {data?.userHotSot?.document?.length > 0 && (
              <Documents data={data?.userHotSot?.document} />
            )}

            <LocationHotshotTable data={data} />

            <TrackWrapper>
              <TableTitle className="doc">Location Detail</TableTitle>

              <TrackConainer>
                <TractLine>
                  <Place>HotShot Started</Place>
                  <Line
                    color={
                      data?.userHotSot?.pickup_en_route
                        ? color?.colorReach
                        : color?.colorUnComplete
                    }
                  />
                  <IconWrap>
                    <img
                      src={data?.userHotSot?.pickup_en_route ? truck : truck2}
                      alt="truck_img"
                      width={50}
                      height={data?.userHotSot?.pickup_en_route ? 50 : 30}
                    />
                  </IconWrap>
                  <Place>
                    {data?.userHotSot?.user_pickup_location ||
                      data?.userHotSot?.user_pickup_address_1 +
                        " " +
                        data?.userHotSot?.user_pickup_address_2 +
                        " " +
                        data?.userHotSot?.user_pickup_city +
                        " " +
                        data?.userHotSot?.user_pickup_state +
                        " " +
                        data?.userHotSot?.user_pickup_zip}
                  </Place>
                </TractLine>
                {locationDetails.map((locationDetail, index) => {
                  const isEnRoute =
                    locationDetail.text === "On Route" &&
                    data?.userHotSot?.pickup_en_route;
                  const isReached =
                    locationDetail.text === "Reached at location" &&
                    data?.userHotSot?.pickup_reached_at_location;
                  const isCollected =
                    locationDetail.text === "Collected" &&
                    data?.userHotSot?.pickup_collected;

                  const textColor =
                    isEnRoute || isReached || isCollected
                      ? color?.colorComplete
                      : data?.userHotSot?.pickup_en_route
                      ? color?.colorReach
                      : color?.colorUnComplete;

                  return (
                    <>
                      <Location key={index}>
                        <LocationText color={textColor}>
                          {locationDetail.text}
                        </LocationText>
                        <LineWrap>
                          <Dot color={textColor}></Dot>
                          <StateLine color={textColor}></StateLine>
                        </LineWrap>
                        <LocationText color={textColor} className="smallText">
                          {locationDetail.description === ""
                            ? data?.userHotSot?.user_pickup_location ||
                              data?.userHotSot?.user_pickup_address_1 +
                                " " +
                                data?.userHotSot?.user_pickup_address_2 +
                                " " +
                                data?.userHotSot?.user_pickup_city +
                                " " +
                                data?.userHotSot?.user_pickup_state +
                                " " +
                                data?.userHotSot?.user_pickup_zip
                            : locationDetail.description}
                        </LocationText>

                        <span className="locationText">
                          {hotShotPickOffText(locationDetail, data?.userHotSot)}
                        </span>
                      </Location>
                    </>
                  );
                })}
              </TrackConainer>

              <TrackConainer>
                <TractLine>
                  <Place>
                    {data?.userHotSot?.user_pickup_location ||
                      data?.userHotSot?.user_pickup_address_1 +
                        " " +
                        data?.userHotSot?.user_pickup_address_2 +
                        " " +
                        data?.userHotSot?.user_pickup_city +
                        " " +
                        data?.userHotSot?.user_pickup_state +
                        " " +
                        data?.userHotSot?.user_pickup_zip}
                  </Place>
                  <Line
                    color={
                      data?.userHotSot?.dopoff_en_route
                        ? color?.colorReach
                        : color?.colorUnComplete
                    }
                  />
                  <IconWrap>
                    <img
                      src={data?.userHotSot?.dopoff_en_route ? truck : truck2}
                      alt="truck_img"
                      width={50}
                      height={data?.userHotSot?.dopoff_en_route ? 50 : 30}
                    />
                  </IconWrap>
                  <Place>
                    {data?.userHotSot?.user_dopoff_location ||
                      data?.userHotSot?.user_dopoff_address_1 +
                        " " +
                        data?.userHotSot?.user_dopoff_address_2 +
                        " " +
                        data?.userHotSot?.user_dopoff_city +
                        " " +
                        data?.userHotSot?.user_dopoff_state +
                        " " +
                        data?.userHotSot?.user_dopoff_zip}
                  </Place>
                </TractLine>
                {jobDropDetails.map((locationDetail, index) => {
                  const isEnRoute =
                    locationDetail.text === "On Route" &&
                    data?.userHotSot?.dopoff_en_route;
                  const isReached =
                    locationDetail.text === "Reached at location" &&
                    data?.userHotSot?.dopoff_reached_at_location;

                  const isDelivered =
                    locationDetail.text === "Delivered at Location" &&
                    data?.userHotSot?.is_delivered;

                  const textColor =
                    isEnRoute || isReached || isDelivered
                      ? color?.colorComplete
                      : data?.userHotSot?.dopoff_en_route
                      ? color?.colorReach
                      : color?.colorUnComplete;

                  return (
                    <>
                      <Location key={index}>
                        <LocationText color={textColor}>
                          {locationDetail.text}
                        </LocationText>
                        <LineWrap>
                          <Dot color={textColor}></Dot>
                          <StateLine color={textColor}></StateLine>
                        </LineWrap>
                        <LocationText color={textColor} className="smallText">
                          {locationDetail.description === ""
                            ? data?.userHotSot?.user_dopoff_location ||
                              data?.userHotSot?.user_dopoff_address_1 +
                                " " +
                                data?.userHotSot?.user_dopoff_address_2 +
                                " " +
                                data?.userHotSot?.user_dopoff_city +
                                " " +
                                data?.userHotSot?.user_dopoff_state +
                                " " +
                                data?.userHotSot?.user_dopoff_zip
                            : locationDetail.description}
                        </LocationText>
                        <span className="locationText">
                          {hotShotDropOffText(locationDetail, data?.userHotSot)}
                        </span>
                      </Location>
                    </>
                  );
                })}
              </TrackConainer>
            </TrackWrapper>
            {data?.userHotSot?.signature && <HotShotSignature data={data} />}
            <Seprators src={seprator} />
          </UserWrapper>
        </InnerContainer>
      </Wrapper>
    </Container>
  );
};

export default AssignedHotShot;
