import {
  DocsWrap,
  DocumentsWrapper,
  DocumnetsContainer,
  DropWrapper,
  PdfWrapper,
  Size,
  Type,
} from "styles/commonStyle";
import { Icon } from "styles/pages/userManagement";
import pdficon from "assets/images/pdf.png";
import viewIcon from "assets/images/view.png";
import { TableTitle } from "styles/dashboard";
import { APIS, APP_BASE_URL } from "api/api.constant";
import { shortenLocation } from "components/commonFunction";

interface DocumentsArray {
  data: any;
}

export const Signature: React.FC<DocumentsArray> = ({ data }) => {
  const hasNonNullSignatures = data.some(
    (item: any) => item.signature !== null
  );

  if (!hasNonNullSignatures) {
    return null;
  }

  return (
    <DocumentsWrapper>
      <TableTitle className="doc">Customer Signature</TableTitle>
      <DocumnetsContainer>
        {data?.map((item: any, index: any) => {
          if (item.signature !== null) {
            return (
              <DocsWrap key={index}>
                <DropWrapper>
                  <p style={{ fontSize: "13px" }}>
                    {item?.drop_off_location ||
                      item?.addressLine1 + " " + item?.addressLine2}
                  </p>
                </DropWrapper>
                <a
                  rel="noreferrer"
                  download
                  target="_blank"
                  className="download_btn"
                  href={item.signature}
                >
                  <Icon src={viewIcon} />
                </a>
              </DocsWrap>
            );
          } else {
            return null;
          }
        })}
      </DocumnetsContainer>
    </DocumentsWrapper>
  );
};
