import moment from "moment";

export const calculateTotalPickupCount = (locationsArray: any) => {
  const totalPickupCount = locationsArray?.reduce(
    (total: any, location: any) => {
      const locationSum = location.pickup_count?.reduce(
        (sum: any, count: any) => sum + count,
        0
      );
      return total + locationSum;
    },
    0
  );
  return totalPickupCount;
};

export function formatPickupCounts(locationInfo: any) {
  const pickups = locationInfo.pickup.map((pick: any, index: any) => {
    return `${locationInfo.pickup_count[index]} ${pick}`;
  });
  return pickups.join(", ");
}

export function formatDropupCounts(locationInfo: any) {
  const dropups = locationInfo.dropup.map((drop: any, index: any) => {
    return `${locationInfo.dropup_count[index]} ${drop}`;
  });
  return dropups.join(", ");
}

export const shortenLocation = (location: string) => {
  const maxLength = 16;
  return location?.length > maxLength
    ? `${location?.substring(0, maxLength)}...`
    : location;
};

export const getLocation = (item: any) => {
  if (item.location) {
    return item.location;
  } else {
    return `${item?.addressLine1} ${item?.addressLine2} ${item?.city} ${item?.state}`;
  }
};

export const getdropLocation = (item: any) => {
  if (item.drop_off_location) {
    return item.drop_off_location;
  } else {
    return `${item?.addressLine1} ${item?.addressLine2} ${item?.city} ${item?.state}`;
  }
};

export const formatLocationText = (locationDetail: any, item: any) => {
  switch (locationDetail.text) {
    case "On Route":
      return (
        item?.en_route &&
        `At ${moment(item.startTime).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Reached at location":
      return (
        item?.reached_at_location &&
        `At ${moment(item.reachTime).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Collected":
      return (
        item?.collected &&
        `At ${moment(item.collectedTime).format("hh:mm a DD/MM/YYYY")}`
      );
    default:
      return null;
  }
};

export const formatDropLocationText = (locationDetail: any, item: any) => {
  switch (locationDetail.text) {
    case "On Route":
      return (
        item?.en_route &&
        `At ${moment(item["startTime"]).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Reached at location":
      return (
        item?.reached_at_location &&
        `At ${moment(item["reachTime"]).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Delivered at Location":
      return (
        item?.is_delivered &&
        `At ${moment(item["delivered_time"]).format("hh:mm a DD/MM/YYYY")}`
      );
    default:
      return null;
  }
};

export const capitalizeFirstLetter = (string: any) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const hotShotPickOffText = (locationDetail: any, item: any) => {
  switch (locationDetail.text) {
    case "On Route":
      return (
        item?.pickup_en_route &&
        `At ${moment(item.pickup_startTime).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Reached at location":
      return item?.pickup_reached_at_location && item?.pickup_reachTime
        ? `At: ${moment(item?.pickup_reachTime).format("hh:mm a DD/MM/YYYY")}`
        : item?.pickup_estimateReachTime
        ? `ETA: ${item?.pickup_estimateReachTime}`
        : "";
    case "Collected":
      return (
        item?.pickup_collected &&
        `At ${moment(item.pickup_collectedTime).format("hh:mm a DD/MM/YYYY")}`
      );
    default:
      return null;
  }
};

export const hotShotDropOffText = (locationDetail: any, item: any) => {
  switch (locationDetail.text) {
    case "On Route":
      return (
        item?.dopoff_en_route &&
        `At ${moment(item.dopoff_startTime).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Reached at location":
      return (
        item?.dopoff_reached_at_location &&
        `At ${moment(item.pickup_reachTime).format("hh:mm a DD/MM/YYYY")}`
      );
    case "Delivered at Location":
      return (
        item?.is_delivered &&
        `At ${moment(item.delivered_time).format("hh:mm a DD/MM/YYYY")}`
      );
    default:
      return null;
  }
};
