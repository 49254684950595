// export const BASE_URL = "http://35.177.231.60:8090"; //admin backend
export const BASE_URL = "https://adminapi.kindracargo.co.uk"; //admin backend
// export const BASE_URL = "http://192.168.1.12:8090";
// export const APP_BASE_URL = "http://35.177.231.60:8083/"; //app backend
export const APP_BASE_URL = "https://api.kindracargo.co.uk/"; //app backend

export const API_PREFIX = "/api/v1/admin";
export const PERSIST_STORAGE = "persist:root";

export const APIS = {
  GET_USERS: "/view/user",
  LOGIN: "/login",
  VERIFY_OTP: "/verify/otp",
  RESEND_OTP: "/resend/otp",
  USER_LISTING: "/view/user",
  UPDATE_USER: "/update/user",
  GET_USER_BY_ID: "/user/details",
  DRIVER_LISTING: "/view/driver",
  BLOCK_USER: "/block",
  UN_BLOCK_USER: "/unblock",
  GET_DRIVER_LISTING: "/view/driver",
  UPDATE_DRIVER: "/update/driver",
  GET_DRIVER_BY_ID: "/driver/details",
  GET_NEW_DRIVER: "/new/driver",
  GET_NEW_DETAIL_DRIVER: "/new/driver/details",
  GET_CMS: "/view/cms",
  UPDATE_CMS: "/cms",
  ACCEPT_DRIVER: "/verify/driver",
  REJECT_DRIVER: "/reject/driver/:id",
  CHANGE_PASSWORD: "/changepassword",
  RESET_PASSWORD: "/edit-reset-password",
  VERIFIED: "/verify/driver",
  REJECTED: "/reject/driver",
  FORGET_PASSWORD: "/forgot-password",
  USER_TODAYJOB: "/user/today/joblist",
  USER_UPCOMINGJOB: "/user/upcoming/joblist",
  USER_PASTJOB: "/user/past/joblist",
  USER_UNASSIGNJOB: "/user/unassign/joblist",
  GET_JOB_DETAILS: "/user/getjobdetails",
  DRIVER_TODAYINJOB: "/driver/today/in-joblist",
  DRIVER_UPCOMINGINJOB: "/driver/upcoming/in-joblist",
  DRIVER_PASTINJOB: "/driver/past/in-joblist",
  DRIVER_UNASSIGNOUTJOB: "/driver/unassign/joblist",
  DRIVER_TODAYOUTJOB: "/driver/today/out-joblist",
  DRIVER_UPCOMINGOUTJOB: "/driver/upcoming/out-joblist",
  DRIVER_PASTOUTJOB: "/driver/past/out-joblist",
  JOB_TODAYJOB: "/today/joblist",
  JOB_UPCOMINGJOB: "/upcoming/joblist",
  JOB_PASTJOB: "/past/joblist",
  JOB_UNASSIGNJOB: "/unassign/joblist",
  JOB_DETAILS: "/getjobdetails",
  JOB_DELETE: "/delete/job",
  JOB_DOWNLOAD: "/download/all/job",
  JOB_DOWNLOAD_INDIVIDUAL: "/download/individual/jobdetails",
  HOTSHORT_ASSIGNED: "/assign/hotshot",
  HOTSHORT_DETAIL: "/hotshot/details",
  HOTSHOT_UNASSIGNED: "/unassign/hotshot",

  // dashboard
  EARNING_BARCHART: "/dashboard/earning/barchat",
  EARNING_DATA: "/dashboard/earning/data",
  USER_REGISTERED: "/dashboard/user-register-data",
  LAST_FIVE_YEAR_DATA: "/dashboard/last-five-user",
  PIE_CHART: "/dashboard/user-register-quarterly",
  JOB_DATA: "/dashboard/job/data",
  LATEST_JOB: "/dashboard/job/list",
  JOB_PIE_CHART: "/dashboard/job/quarterly",

  // finance
  DROP_DOWNLIST: "/user/dropdownlist",
  FINANCE_JOB_LIST: "/finance/job/list",
  DOWNLOAD_INVOICE: "/finance/invoice/download",
  UPDATE_INVOICE: "/finance/update/status",
  GET_LAST_INVOICE: "/finance/getlast/invoice",
};

export const locationDetails = [
  {
    text: "On Route",
    description: "",
  },
  {
    text: "Reached at location",
    description: "Arrived at Location",
  },
  {
    text: "Collected",
    description: "Collected",
  },
];

export const jobDropDetails = [
  {
    text: "On Route",
    description: "",
  },
  {
    text: "Reached at location",
    description: "Arrived at Location",
  },
  {
    text: "Delivered at Location",
    description: "Delivered",
  },
];

export const color = {
  colorComplete: "#01851e",
  colorReach: "#F7931E",
  colorUnComplete: "#606161",
};
