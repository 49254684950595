import { Table } from "antd";
import { Amount, TableWrapper } from "styles/dashboard";
import {
  Filter,
  FilterIcon,
  FilterWrapper,
  RightSec,
  TableTitle,
  Text,
  Reset,
} from "styles/pages/userManagement";
import filterIcone from "assets/images/filterIcon.png";
import { SearchableInput } from "components";

const TableContainer = ({
  isSearchAble = false,
  onResetClick = () => {},
  title,
  data,
  colums,
  setfilter,
  filter,
  type,
  amount,
  totalCount,
  onPaginationChange,
  filtertext,
  onSearchChange,
  filterValue,
  page,
  activeFilterTab,
  activeTab,
}: any) => {
  return (
    <TableWrapper>
      <FilterWrapper>
        {isSearchAble ? (
          <SearchableInput
            onSearchChange={onSearchChange}
            filterValue={filterValue}
          />
        ) : (
          <TableTitle>{title}</TableTitle>
        )}
        <RightSec>
          {amount && (
            <Amount>
              <span>Total Amount :</span>
              {data?.length > 0
                ? data
                    ?.reduce(
                      (total: any, item: any) =>
                        total + Number(item?.amount || 0),
                      0
                    )
                    .toLocaleString("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    })
                : 0}
            </Amount>
          )}
          {filterValue?.date_from && (
            <Reset onClick={onResetClick}>
              <Text>Reset</Text>
            </Reset>
          )}
          {activeFilterTab !== 0 && activeTab !== 0 && (
            <Filter onClick={() => type === "user" && setfilter(!filter)}>
              <Text>{filtertext}</Text>
              <FilterIcon src={filterIcone} />
            </Filter>
          )}
        </RightSec>
      </FilterWrapper>
      <Table
        className="tableStyle"
        columns={colums}
        dataSource={data}
        pagination={{
          total: totalCount,
          onChange: onPaginationChange,
          current: page,
        }}
      />
    </TableWrapper>
  );
};

export default TableContainer;
