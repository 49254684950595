import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import Navbar from "components/navbar";
import { CSM_SLUG_TYPE } from "constants/common";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const MobilePrivacyPolicyView = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    postApi(APIS.GET_CMS, {
      type: CSM_SLUG_TYPE.PRIVACY_POLICY,
    })
      .then((res) => {
        let response: any = res;
        setData(response?.data?.content);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <Container>
      <Wrapper>
        <Title>Privacy Policy</Title>
        <Content dangerouslySetInnerHTML={{ __html: data }}></Content>
      </Wrapper>
    </Container>
  );
};
