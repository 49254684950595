import { Container, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { DataType, TodayJobData, data } from "constants/tableData";
import { ColumnsType } from "antd/es/table";
import { Action, UserImage, UserNameWrapper } from "styles/dashboard";
import viewIcone from "assets/images/view.png";
import { ActionsWrapper, Icon } from "styles/pages/userManagement";
import { useLocation, useNavigate } from "react-router-dom";
import TableContainer from "components/TableContainer";
import { FilterValue } from "interfaces/iCommon";
import {
  DATE_FILTERS,
  PAGINATION_SIZE,
  filterInitialValue,
} from "constants/common";
import { useDispatch } from "react-redux";
import { postApi } from "api/api.client";
import { APIS, APP_BASE_URL } from "api/api.constant";
import { useEffect, useState } from "react";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { CommonFilter } from "components";
import { disabledPastAndTodayJob } from "components/dateInput";
import {
  calculateTotalPickupCount,
  capitalizeFirstLetter,
  shortenLocation,
} from "components/commonFunction";
import UserIcon from "../../../assets/images/no-user.jpg";

export const UpcomingJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page");
  const [filter, setfilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeFilterTab, setActiveFilterTab] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGINATION_SIZE);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filterValue, setFilterValue] =
    useState<FilterValue>(filterInitialValue);
  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.JOB_UPCOMINGJOB, {
      ...filterValue,
      page: page,
      size: pageSize,
    })
      .then((res) => {
        let response: any = res;
        setTableData(response?.data?.jobInfo);
        setTotalCount(response?.data?.pagination?.totalCount);
      })
      .catch((err) => {})
      .finally(() => {
        // setFilterValue(filterInitialValue);
        dispatch(toggleLoader({ loader: false }));
      });
  }, [triggerFilter, page, pageSize, refresh]);

  useEffect(() => {
    const handleSelect = () => {
      queryParams.set("page", String(page));
      const newUrl = `${location.pathname}?${queryParams}`;
      window.history.replaceState(null, "", newUrl);
    };
    handleSelect();
  }, [page]);

  const Todaycolumns: ColumnsType<DataType> = [
    {
      title: "Name",
      key: "name",
      render: (_, record: any) => (
        <UserNameWrapper>
          <UserImage
            src={
              record?.createdBy?.photo !== null
                ? record?.createdBy?.photo
                : UserIcon
            }
          />
          {capitalizeFirstLetter(
            record?.createdBy?.full_name ? record?.createdBy?.full_name : ""
          )}
        </UserNameWrapper>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.locations[0]?.location ||
          record?.locations[0]?.addressLine1 +
            " " +
            record?.locations[0]?.addressLine2;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",

      render: (_, record: any) => {
        const lastLocation =
          record?.jobDropOff[record?.jobDropOff?.length - 1]
            ?.drop_off_location ||
          record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine1 +
            " " +
            record?.jobDropOff[record?.jobDropOff?.length - 1]?.addressLine2;
        return <div>{lastLocation}</div>;
      },
    },
    {
      title: <Action>Total Stops</Action>,
      dataIndex: "totalStops",
      key: "Total Stops",
      render: (_, record) => {
        return <Action>{record?.totalStops}</Action>;
      },
    },
    {
      title: "Total Items",
      dataIndex: "totalItems",
      render: (_, record) => {
        return <div>{calculateTotalPickupCount(record?.locations)}</div>;
      },
    },

    {
      title: <Action>Actions</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper>
            <Icon
              src={viewIcone}
              onClick={() =>
                navigate(`/job-management/upcoming/${record?._id}`)
              }
            />
          </ActionsWrapper>
        );
      },
    },
  ];
  const onResetClick = () => {
    setFilterValue(filterInitialValue);
    setTriggerFilter(!triggerFilter);
  };

  const handleOk = () => {
    setfilter(false);
  };

  const handleCancel = () => {
    setfilter(false);
  };

  const onSearchClick = () => {
    if (filterValue.date_from !== null && filterValue.date_to !== null) {
      setTriggerFilter(!triggerFilter);
      setfilter(!filter);
      setPage(1);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>Upcoming Job List</Title>
        <TableContainer
          colums={Todaycolumns}
          data={tableData}
          onResetClick={onResetClick}
          setfilter={setfilter}
          filter={filter}
          filterValue={filterValue}
          type="user"
          filtertext={"Filter"}
          totalCount={totalCount}
          onPaginationChange={(page: any, pageSize: any) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />

        {filter && (
          <CommonFilter
            filter={filter}
            handleOk={handleOk}
            handleCancel={handleCancel}
            DATE_FILTERS={DATE_FILTERS}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onSearchClick={onSearchClick}
            disabledDate={disabledPastAndTodayJob}
            job_type="upcomming_job"
            isDisabled={filterValue.date_from === "" ? true : false}
          />
        )}
      </Wrapper>
    </Container>
  );
};
