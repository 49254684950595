import styled from "styled-components";

export const CancleReasonWrap = styled.div`
  padding-left: 30px;
`;

export const Confirmation = styled.div`
  color: #f34235;
  font-size: 26px;
  font-weight: 700;
  padding-left: 10px;
`;
export const Reason = styled.div`
  padding: 10px;
  font-size: 16px;
`;
