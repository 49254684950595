import dayjs from "dayjs";

export const disabledFutureDate = (current: any) => {
  console.log(
    "date in disbledfuture date",
    current && current > dayjs().endOf("day")
  );

  return current && current > dayjs().endOf("day");
};

export const disabledPastDate = (current: any) => {
  return current && current < dayjs().startOf("day");
};

export const disabledTodate = (current: any) => {
  // Compare the current date with today's date
  return current && dayjs(current).isSame(dayjs(), "day");
};

export const disabledPastAndTodayJob = (jobDate: any) => {
  const today = dayjs().startOf("day");
  const jobDay = dayjs(jobDate).startOf("day");

  // Check if the job date is before or equal to today's date
  return jobDay.isBefore(today, "day") || jobDay.isSame(today, "day");
};

export const dateDisabled = (
  disabledDate: any,
  date_from: any,
  maxDate: any,
  current: any,
  i: any
) => {
  if (i === 0) return disabledDate !== false ? disabledDate(current) : false;
  else
    return (
      (date_from && current && current.isBefore(date_from, "day")) ||
      (current && current.isAfter(maxDate, "day"))
    );
};

export const dateDisabledUpcomming = (
  disabledDate: any,
  date_from: any,
  maxDate: any,
  current: any,
  i: any
) => {
  if (i === 0) return disabledDate !== false ? disabledDate(current) : false;
  else return date_from && current && current.isBefore(date_from, "day");
};
