import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  DocsWrap,
  PdfDetail,
  PdfWrapper,
  Size,
  Type,
  DocumentWrapper,
} from "styles/commonStyle";
import { Container, Title } from "styles/dashboard";
import { Icon, Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";

import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import pdficon from "assets/images/pdf.png";
import viewIcon from "assets/images/view.png";
import {
  AcceptButton,
  RejectButton,
  ButtonsWrapper,
} from "styles/newDriverDetail";
import { DriverComponent } from "components/driverDetails";
import { postApi } from "api/api.client";
import { APIS, APP_BASE_URL } from "api/api.constant";
import { useDispatch } from "react-redux";
import { SyntheticEvent } from "react";
import seprator from "assets/images/seprator.png";
import { TOAST_MESSAGE } from "constants/common";
import { getToast } from "utils";
import moment from "moment";

interface DocumentsArray {
  data: any;
}

export const DriverDocuments: React.FC<DocumentsArray> = ({ data }) => {
  return (
    <>
      <img style={{ width: "100%" }} src={seprator} alt="seprator" />
      <VahicleWrapper className="customeStyle">
        <DocumentWrapper>
          <Data>Driving Licence Documents</Data>
          {data?.map((item: any, index: any) =>
            item.key.includes("drivingLicence") && item?.docurl != null ? (
              <DocsWrap key={index}>
                <PdfWrapper>
                  <Icon src={pdficon} className="pdf" />
                  <PdfDetail>
                    <Type>Licence </Type>
                  </PdfDetail>
                </PdfWrapper>
                <a
                  rel="noreferrer"
                  download
                  target="_blank"
                  className="download_btn"
                  href={item.docurl}
                >
                  <Icon src={viewIcon} />
                </a>
              </DocsWrap>
            ) : null
          )}
        </DocumentWrapper>
      </VahicleWrapper>

      <VahicleWrapper className="customeStyle">
        <DocumentWrapper>
          <Data>Insurance Certificate Documents</Data>
          {data?.map((item: any, index: any) =>
            item.key.includes("insuranceCertificate") &&
            item?.docurl != null ? (
              <DocsWrap key={index}>
                <PdfWrapper>
                  <Icon src={pdficon} className="pdf" />
                  <PdfDetail>
                    <Type>Certificate </Type>
                  </PdfDetail>
                </PdfWrapper>
                <a
                  rel="noreferrer"
                  download
                  target="_blank"
                  className="download_btn"
                  href={item.docurl}
                >
                  <Icon src={viewIcon} />
                </a>
              </DocsWrap>
            ) : null
          )}
        </DocumentWrapper>
      </VahicleWrapper>
    </>
  );
};
