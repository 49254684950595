import {
  DocsWrap,
  DocumentsWrapper,
  DocumnetsContainer,
  DropWrapper,
  PdfWrapper,
  Size,
  Type,
} from "styles/commonStyle";
import { Icon } from "styles/pages/userManagement";
import pdficon from "assets/images/pdf.png";
import viewIcon from "assets/images/view.png";
import { TableTitle } from "styles/dashboard";
import { APIS, APP_BASE_URL } from "api/api.constant";
import { shortenLocation } from "components/commonFunction";

interface DocumentsArray {
  data: any;
}

export const HotShotSignature: React.FC<DocumentsArray> = ({ data }) => {
  return (
    <DocumentsWrapper>
      <TableTitle className="doc">Customer Signature</TableTitle>
      <DocumnetsContainer>
        <DocsWrap>
          <DropWrapper>
            <p style={{ fontSize: "13px" }}>
              {data?.userHotSot?.user_dopoff_location ||
                data?.userHotSot?.user_dopoff_address_1 +
                  " " +
                  data?.userHotSot?.user_dopoff_address_2 +
                  " " +
                  data?.userHotSot?.user_dopoff_city +
                  " " +
                  data?.userHotSot?.user_dopoff_state +
                  " " +
                  data?.userHotSot?.user_dopoff_zip}
            </p>
          </DropWrapper>
          <a
            rel="noreferrer"
            download
            target="_blank"
            className="download_btn"
            href={data?.userHotSot?.signature}
          >
            <Icon src={viewIcon} />
          </a>
        </DocsWrap>
      </DocumnetsContainer>
    </DocumentsWrapper>
  );
};
