import {
  LocationDetails,
  LocationWrapper,
  DetailCard,
  Head,
  Table,
  TableSpacing,
  TableRow,
  TableCell,
  TableCellLocation,
  TableLocatoinTitle,
} from "styles/unAssignedJob";

import {
  formatDropupCounts,
  formatPickupCounts,
} from "components/commonFunction";

export const LocationHotshotTable = ({ data }: any) => {
  const pickOFfData =
    data?.userHotSot?.user_pickup_location ||
    data?.userHotSot?.user_pickup_address_1 +
      " " +
      data?.userHotSot?.user_pickup_address_2 +
      " " +
      data?.userHotSot?.user_pickup_city +
      " " +
      data?.userHotSot?.user_pickup_state +
      " " +
      data?.userHotSot?.user_pickup_zip;
  const dropOffData =
    data?.userHotSot?.user_dopoff_location ||
    data?.userHotSot?.user_dopoff_address_1 +
      " " +
      data?.userHotSot?.user_dopoff_address_2 +
      " " +
      data?.userHotSot?.user_dopoff_city +
      " " +
      data?.userHotSot?.user_dopoff_state +
      " " +
      data?.userHotSot?.user_dopoff_zip;

  return (
    <>
      <TableLocatoinTitle className="doc">
        Location Information
      </TableLocatoinTitle>
      <LocationWrapper>
        <LocationDetails>
          <DetailCard>
            <Head>Pickup Information</Head>
            <Table>
              <TableRow>
                <TableCellLocation>Address</TableCellLocation>
                <TableCellLocation>Items </TableCellLocation>
              </TableRow>
              {/* {locationsData.map((item: any, index: number) => ( */}
              <TableRow>
                <TableCellLocation>{pickOFfData}</TableCellLocation>
                <TableCellLocation>
                  <TableSpacing>
                    {data?.userHotSot?.pickup_item_count}{" "}
                    {data?.userHotSot?.pickup_item}
                  </TableSpacing>
                </TableCellLocation>
              </TableRow>
              {/* ))} */}
            </Table>
          </DetailCard>
        </LocationDetails>
        <LocationDetails>
          <DetailCard>
            <Head>Drop Off Information</Head>
            <Table>
              <TableRow>
                <TableCellLocation>Address</TableCellLocation>
                <TableCellLocation>Items</TableCellLocation>
              </TableRow>
              {/* {jobDropOffData.map((item: any, index: number) => ( */}
              <TableRow>
                <TableCellLocation>{dropOffData}</TableCellLocation>
                <TableCellLocation>
                  <TableSpacing>
                    {data?.userHotSot?.dropoff_item_count}{" "}
                    {data?.userHotSot?.dropoff_item}
                  </TableSpacing>
                </TableCellLocation>
              </TableRow>
              {/* ))} */}
            </Table>
          </DetailCard>
        </LocationDetails>
      </LocationWrapper>
    </>
  );
};
