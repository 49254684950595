import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  NoteWrapper,
  TableLocatoinTitle,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";

import { useEffect, useState } from "react";
import { NameWrap, NameText, InnerContainer } from "styles/jobManagement";
import { DriverComponent } from "components/driverDetails";
import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import moment from "moment";
import { JobNote } from "components/jobNote";
import { LocationTable } from "components/locationTable";
import { calculateTotalPickupCount } from "components/commonFunction";

const TodayJobdetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.JOB_DETAILS}`, { jobId: id })
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    {
      label: "Amount",
      data: `${Number(data?.amount || 0)?.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      })}`,
    },

    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
    {
      label: "Weight",
      data: data?.weight + " Kg" || 0,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Today's Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <InnerContainer>
          <UserWrapper>
            <NameWrap>
              <NameText>Driver details</NameText>
            </NameWrap>
            <DriverComponent data={data?.driverId} />
          </UserWrapper>

          <UserWrapper>
            <NameWrap>
              <NameText>User details</NameText>
            </NameWrap>
            <UserComponent data={data?.createdBy} />
          </UserWrapper>

          <TableTitle>Vehicle Detail</TableTitle>
          <VahicleWrapper>
            {vehicleData.map(({ label, data }) => (
              <TextWrapper>
                <Label>
                  <div>{label}</div>
                </Label>
                <DataWrapper>{data}</DataWrapper>
              </TextWrapper>
            ))}
          </VahicleWrapper>

          {data?.note?.length > 0 && <JobNote type="Note" data={data?.note} />}
          {data?.document?.length > 0 && <Documents data={data?.document} />}

          {locationsData?.length > 0 && (
            <LocationTable
              locationsData={locationsData}
              jobDropOffData={jobDropOffData}
            />
          )}
        </InnerContainer>
      </Wrapper>
    </Container>
  );
};

export default TodayJobdetail;
