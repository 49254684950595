import { UserImage, UserNameWrapper } from "styles/dashboard";
import UserIcon from "../../assets/images/no-user.jpg";
import { BASE_URL, APP_BASE_URL } from "api/api.constant";
import { TruncatedText } from "utils";
import { capitalizeFirstLetter } from "components/commonFunction";

interface ListingUserInfoProps {
  record: {
    photo?: string;
    full_name?: string;
  };
}

const ListingUserInfo: React.FC<ListingUserInfoProps> = ({ record }) => {
  return (
    <UserNameWrapper>
      <UserImage src={record?.photo !== null ? record?.photo : UserIcon} />
      <>
        {capitalizeFirstLetter(
          TruncatedText(record?.full_name ? record?.full_name : "")
        )}
      </>
    </UserNameWrapper>
  );
};
export default ListingUserInfo;
