import AuthSidebar from "components/authSidebar";
import {
  Container,
  InputWrapper,
  Form,
  Input,
  Label,
  LoginContainer,
  Logo,
  LogoWrapper,
  Title,
  Wrapper,
  Button,
  ButtonWrapper,
  ForgetPassword,
} from "styles/pages/Login";
import logo from "assets/images/Logo.png";
import { CiMail } from "react-icons/ci";
import { useState } from "react";
import { BackText, SubmitButtonWrapper } from "styles/forgotPasswordStyle";
import { BackGroundLogo, BackImage } from "styles/verificationStyle";
import backImage from "assets/images/Logo2.png";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent } from "react";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { useDispatch } from "react-redux";
import { getToast } from "utils";
import { TOAST_MESSAGE } from "constants/common";
import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import { useFormik } from "formik";
import FieldError from "components/fieldError";
import * as Yup from "yup";

const ForgotPasword = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (values: { email: string }) => {
    dispatch(toggleLoader({ loader: true }));

    const { email } = values;
    setLoading(true);
    postApi(APIS.FORGET_PASSWORD, {
      email: email,
    })
      .then((result: any) => {
        getToast("success", TOAST_MESSAGE.EMAIL_SENT);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Required*"),
    }),
    enableReinitialize: true,
  });
  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    setTouched,
  }: any = formik;

  return (
    <Container>
      <BackGroundLogo>
        <BackImage src={backImage} />
      </BackGroundLogo>
      <LoginContainer>
        <LogoWrapper>
          <Logo src={logo} />
        </LogoWrapper>
        <Title>Forgot Password</Title>
        <Form onSubmit={handleSubmit}>
          <Wrapper>
            <Label>Email</Label>
            <InputWrapper className="input">
              <CiMail color="#A1A1A1" size={30} />
              <Input
                onBlur={() => setTouched({ ...touched, email: true })}
                value={values?.email}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                }}
                type="email"
                placeholder="Email"
              />
            </InputWrapper>
            <FieldError
              width="500px"
              fieldName={"email"}
              errors={errors}
              touched={touched}
            />
          </Wrapper>
          <SubmitButtonWrapper type="submit">
            <Button>{isLoading ? "Please wait" : "Submit"}</Button>
          </SubmitButtonWrapper>
          <BackText onClick={() => navigate("/login")}>
            Back To <span>Login</span>
          </BackText>
        </Form>
      </LoginContainer>
      <AuthSidebar />
    </Container>
  );
};

export default ForgotPasword;
