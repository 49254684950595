// import {
//   Container,
//   Icon,
//   Logo,
//   LogoWrapper,
//   ManuWrapper,
// } from "styles/sidebarStyle";
// import { Modal } from "antd";
// import { ModalContent, FilterSearchButton } from "styles/pages/userManagement";
// import { ModalTitle, ButtonWrapper, Reject, Accept } from "styles/modal";
// import logo from "assets/images/Logo.png";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { RxDashboard } from "react-icons/rx";
// import { LuUser2 } from "react-icons/lu";
// import { CiStar } from "react-icons/ci";
// import { IoBagRemoveOutline } from "react-icons/io5";
// import { CiSettings } from "react-icons/ci";
// import { MenuProps } from "antd";
// import { Menu } from "antd";
// import { useEffect, useState } from "react";
// import MenuItem from "antd/es/menu/MenuItem";
// import driverIcon from "assets/images/driver.png";
// import financeIcon from "assets/images/finance.png";
// import job from "assets/images/job.png";
// import logoutIcon from "assets/images/logoutIcon.png";

// import { ConfirmationRow } from "styles/updateProfile";
// import { useDispatch } from "react-redux";
// import { logoutUser } from "../../redux/slices/authSlice";

// type MenuItem = Required<MenuProps>["items"][number];
// function getItem(
//   label: React.ReactNode,
//   key: React.Key,
//   icon?: React.ReactNode,
//   path?: string,
//   children?: MenuItem[],
//   type?: "group"
// ): MenuItem {
//   return {
//     key,
//     icon,
//     children,
//     label,
//     type,
//     path,
//   } as MenuItem;
// }

// const items: MenuItem[] = [
//   getItem("Dashboard", "1", <RxDashboard size={24} />, "/dashboard"),
//   getItem("User Management", "2", <LuUser2 size={24} />, "/user-management"),
//   getItem(
//     "Driver Management",
//     "3",
//     <Icon src={driverIcon} />,
//     "/driver-management/List",
//     [
//       getItem(
//         "Driver Management List",
//         "4",
//         undefined,
//         "/driver-management/List"
//       ),
//       // getItem("New Driver", "5", undefined, "/driver-management/new-driver"),
//       getItem("New Driver", "5", undefined, "/new-driver"),
//     ]
//   ),
//   getItem(
//     "Hotshot Management",
//     "6",
//     <CiStar size={24} />,
//     "/hotshot-management/assigned",
//     [
//       getItem(
//         "Assigned Hotshot",
//         "18",
//         undefined,
//         "/hotshot-management/assigned"
//       ),
//       getItem(
//         "Unassigned Hotshot",
//         "19",
//         undefined,
//         "/hotshot-management/unassigned"
//       ),
//     ]
//   ),
//   getItem("Jobs Management", "7", <Icon src={job} />, "/job-management/today", [
//     getItem("Today's Job", "14", undefined, "/job-management/today"),
//     getItem("Upcoming Job", "15", undefined, "/job-management/upcoming"),
//     getItem("Past Job", "16", undefined, "/job-management/job-past"),
//     getItem("Unassigned Job", "17", undefined, "/job-management/unassigned"),
//   ]),
//   getItem(
//     "Finance Management",
//     "8",
//     <Icon src={financeIcon} />,
//     "/finance-management"
//   ),
//   getItem("Settings", "9", <CiSettings size={24} />, "/settings/about-us", [
//     getItem("About Us", "10", undefined, "/settings/about-us"),
//     getItem(
//       "Terms & Conditions",
//       "11",
//       undefined,
//       "/settings/terms_conditions"
//     ),
//     getItem("Privacy Policy", "12", undefined, "/settings/privacy-policy"),
//     getItem("Change Password", "13", undefined, "/settings/change-password"),
//   ]),
// ];

// const rootSubmenuKeys = ["1", "2", "3", "4", "5", "6", "7"];
// const Sidebar = () => {
//   const [openKeys, setOpenKeys] = useState<any>([""]);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [logout, setLogout] = useState(false);
//   const dispatch = useDispatch();

//   const path = window.location.pathname.split("/");

//   console.log(path, openKeys, "path");

//   //for child or sub menu item
//   const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
//     const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) === -1);

//     console.log(latestOpenKey, "latest open key");
//     if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
//       setOpenKeys(keys);
//     } else {
//       setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
//     }
//   };

//   // for default Open Key(to highight parent menu)
//   const OpenKey = (path: any) => {
//     switch (path[1]) {
//       case "driver-management":
//         return ["3"];
//       case "new-driver":
//         return ["3"];
//       case "hotshot-management":
//         return ["6"];
//       case "job-management":
//         return ["7"];
//       case "settings":
//         return ["9"];
//       case "about-us":
//         return ["9"];
//       case "terms_conditions":
//         return ["9"];
//       case "privacy-policy":
//         return ["9"];
//       default:
//         return ["1"];
//     }
//   };

//   // for default selected Key (For main menu items)
//   // const SelectedOpenKey = (path: any) => {
//   //   console.log(path, openKeys, "this is selected");
//   //   switch (path[1]) {
//   //     case "user-management":
//   //       return ["2"];
//   //     case "driver-management":
//   //       return ["4"];
//   //     case "new-driver":
//   //       return ["5"];
//   //     // case "driver-management":
//   //     //   return path[2] === "List" ? ["4"] : ["5"];

//   //     case "hotshot-management":
//   //       return path[2] === "assigned" ? ["18"] : ["19"];
//   //     case "job-management":
//   //       return path[2] === "today"
//   //         ? ["14"]
//   //         : path[2] === "upcoming"
//   //         ? ["15"]
//   //         : path[2] === "job-past"
//   //         ? ["16"]
//   //         : ["17"];
//   //     case "finance-management":
//   //       return ["8"];
//   //     case "settings":
//   //       return path[2] === "about-us"
//   //         ? ["10"]
//   //         : path[2] === "terms_conditions"
//   //         ? ["11"]
//   //         : path[2] === "privacy-policy"
//   //         ? ["12"]
//   //         : ["13"];
//   //     case "about-us":
//   //       return ["10"];
//   //     case "terms_conditions":
//   //       return ["11"];
//   //     case "privacy-policy":
//   //       return ["12"];
//   //     default:
//   //       return ["1"];
//   //   }
//   // };

//   const SelectedOpenKey = (path: any) => {
//     console.log(path, openKeys, "this is selected");
//     if (
//       openKeys[0] === "3" ||
//       openKeys[0] === "6" ||
//       openKeys[0] === "7" ||
//       openKeys[0] === "9"
//     ) {
//       switch (openKeys[0]) {
//         case "3":
//           if (path[1] === "new-driver") return ["5"];
//           else return ["4"];
//         case "6":
//           if (path[1] === "hotshot-management") {
//             return path[2] === "assigned"
//               ? ["18"]
//               : path[2] === "unassigned"
//               ? ["19"]
//               : ["18"];
//           } else return ["18"];
//         case "7":
//           if (path[1] === "job-management") {
//             return path[2] === "today"
//               ? ["14"]
//               : path[2] === "upcoming"
//               ? ["15"]
//               : path[2] === "job-past"
//               ? ["16"]
//               : ["14"];
//           } else return ["14"];
//         case "9":
//           if (path[1] === "settings") {
//             return path[2] === "about-us"
//               ? ["10"]
//               : path[2] === "terms_conditions"
//               ? ["11"]
//               : path[2] === "privacy-policy"
//               ? ["12"]
//               : path[2] === "change-password"
//               ? ["13"]
//               : ["10"];
//           } else return ["10"];
//         default:
//           return [""];
//       }
//     } else {
//       switch (path[1]) {
//         case "user-management":
//           return ["2"];
//         case "driver-management":
//           return ["4"];
//         case "new-driver":
//           return ["5"];
//         // case "driver-management":
//         //   return path[2] === "List" ? ["4"] : ["5"];

//         case "hotshot-management":
//           return path[2] === "assigned" ? ["18"] : ["19"];
//         case "job-management":
//           return path[2] === "today"
//             ? ["14"]
//             : path[2] === "upcoming"
//             ? ["15"]
//             : path[2] === "job-past"
//             ? ["16"]
//             : ["17"];
//         case "finance-management":
//           return ["8"];
//         case "settings":
//           return path[2] === "about-us"
//             ? ["10"]
//             : path[2] === "terms_conditions"
//             ? ["11"]
//             : path[2] === "privacy-policy"
//             ? ["12"]
//             : ["13"];
//         case "about-us":
//           return ["10"];
//         case "terms_conditions":
//           return ["11"];
//         case "privacy-policy":
//           return ["12"];
//         default:
//           return ["1"];
//       }
//     }
//   };

//   useEffect(() => {
//     setOpenKeys(OpenKey(path));
//   }, [location]);

//   const onConfirmClick = () => {
//     dispatch(logoutUser());
//     localStorage.clear();
//     navigate("/login?success=true");
//   };

//   return (
//     <Container>
//       <LogoWrapper
//         onClick={() => {
//           navigate("/");
//         }}
//       >
//         <Logo src={logo} />
//       </LogoWrapper>
//       <ManuWrapper>
//         <Menu
//           mode="inline"
//           openKeys={openKeys}
//           onOpenChange={onOpenChange}
//           style={{ width: 240 }}
//           selectedKeys={SelectedOpenKey(path)}
//         >
//           {items.map((item: any) => {
//             if (item.children) {
//               return (
//                 <Menu.SubMenu
//                   key={item.key}
//                   icon={item.icon}
//                   title={item.label}
//                 >
//                   {item.children.map((child: any) => (
//                     <Menu.Item key={child.key}>
//                       <Link to={child.path}>{child.label}</Link>
//                     </Menu.Item>
//                   ))}
//                 </Menu.SubMenu>
//               );
//             }

//             return (
//               <Menu.Item key={item.key} icon={item.icon}>
//                 <Link to={item.path}>{item.label}</Link>
//               </Menu.Item>
//             );
//           })}

//           <Menu.Item
//             icon={<Icon src={logoutIcon} />}
//             onClick={() => setLogout(true)}
//           >
//             Logout
//           </Menu.Item>
//         </Menu>
//       </ManuWrapper>

//       <Modal
//         open={logout}
//         onOk={onConfirmClick}
//         onCancel={() => setLogout(false)}
//         footer={false}
//         centered
//       >
//         <ModalContent>
//           <ModalTitle>Are you sure you want to logout?</ModalTitle>
//           <div style={{ fontSize: "16px", marginTop: "6px" }}></div>
//           <ConfirmationRow>
//             <ButtonWrapper
//               onClick={() => setLogout(false)}
//               style={{ paddingTop: "14px" }}
//             >
//               <Reject style={{ height: "30px", width: "100px" }}>No</Reject>
//             </ButtonWrapper>
//             <ButtonWrapper
//               onClick={onConfirmClick}
//               style={{ paddingTop: "14px" }}
//             >
//               <Accept style={{ height: "30px", width: "100px" }}>yes</Accept>
//             </ButtonWrapper>
//           </ConfirmationRow>
//         </ModalContent>
//       </Modal>
//     </Container>
//   );
// };

// export default Sidebar;

import {
  Container,
  Icon,
  Logo,
  LogoWrapper,
  ManuWrapper,
} from "styles/sidebarStyle";
import { Modal } from "antd";
import { ModalContent, FilterSearchButton } from "styles/pages/userManagement";
import { ModalTitle, ButtonWrapper, Reject, Accept } from "styles/modal";
import logo from "assets/images/Logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { LuUser2 } from "react-icons/lu";
import { CiStar } from "react-icons/ci";
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { MenuProps } from "antd";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import MenuItem from "antd/es/menu/MenuItem";
import driverIcon from "assets/images/driver.png";
import financeIcon from "assets/images/finance.png";
import job from "assets/images/job.png";
import logoutIcon from "assets/images/logoutIcon.png";

import { ConfirmationRow } from "styles/updateProfile";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/slices/authSlice";

type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  path?: string,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    path,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "1", <RxDashboard size={24} />, "/dashboard"),
  getItem("User Management", "2", <LuUser2 size={24} />, "/user-management"),
  getItem(
    "Driver Management",
    "3",
    <Icon src={driverIcon} />,
    "/navigation-two",
    [
      getItem(
        "Driver Management List",
        "4",
        undefined,
        "/driver-management/List"
      ),
      // getItem("New Driver", "5", undefined, "/driver-management/new-driver"),
      getItem("New Driver", "5", undefined, "/new-driver"),
    ]
  ),
  getItem("Hotshot Management", "6", <CiStar size={24} />, "", [
    getItem(
      "Assigned Hotshot",
      "18",
      undefined,
      "/hotshot-management/assigned"
    ),
    getItem(
      "Unassigned Hotshot",
      "19",
      undefined,
      "/hotshot-management/unassigned"
    ),
  ]),
  getItem("Jobs Management", "7", <Icon src={job} />, "", [
    getItem("Today's Job", "14", undefined, "/job-management/today"),
    getItem("Upcoming Job", "15", undefined, "/job-management/upcoming"),
    getItem("Past Job", "16", undefined, "/job-management/job-past"),
    getItem("Unassigned Job", "17", undefined, "/job-management/unassigned"),
  ]),
  getItem(
    "Finance Management",
    "8",
    <Icon src={financeIcon} />,
    "/finance-management"
  ),
  getItem("Settings", "9", <CiSettings size={24} />, "/aaaaa", [
    getItem("About Us", "10", undefined, "/settings/about-us"),
    getItem(
      "Terms & Conditions",
      "11",
      undefined,
      "/settings/terms_conditions"
    ),
    getItem("Privacy Policy", "12", undefined, "/settings/privacy-policy"),
    getItem("Change Password", "13", undefined, "/settings/change-password"),
  ]),
];

const rootSubmenuKeys = ["1", "2", "3", "4", "5", "6", "7"];
const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState<any>([""]);
  const navigate = useNavigate();
  const location = useLocation();
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();

  const path = window.location.pathname.split("/");
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    console.log("keys==>", keys);
    const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // for default Open Key
  const OpenKey = (path: any) => {
    switch (path[1]) {
      case "driver-management":
        return ["3"];
      case "new-driver":
        return ["3"];
      case "hotshot-management":
        return ["6"];
      case "job-management":
        return ["7"];
      case "settings":
        return ["9"];
      case "about-us":
        return ["9"];
      case "terms_conditions":
        return ["9"];
      case "privacy-policy":
        return ["9"];
      default:
        return ["1"];
    }
  };

  // for default selected Key
  const SelectedOpenKey = (path: any) => {
    switch (path[1]) {
      case "user-management":
        return ["2"];
      case "driver-management":
        return ["4"];
      case "new-driver":
        return ["5"];
      // case "driver-management":
      //   return path[2] === "List" ? ["4"] : ["5"];

      case "hotshot-management":
        return path[2] === "assigned" ? ["18"] : ["19"];
      case "job-management":
        return path[2] === "today"
          ? ["14"]
          : path[2] === "upcoming"
          ? ["15"]
          : path[2] === "job-past"
          ? ["16"]
          : ["17"];
      case "finance-management":
        return ["8"];
      case "settings":
        return path[2] === "about-us"
          ? ["10"]
          : path[2] === "terms_conditions"
          ? ["11"]
          : path[2] === "privacy-policy"
          ? ["12"]
          : ["13"];
      case "about-us":
        return ["10"];
      case "terms_conditions":
        return ["11"];
      case "privacy-policy":
        return ["12"];
      default:
        return ["1"];
    }
  };

  useEffect(() => {
    setOpenKeys(OpenKey(path));
  }, [location]);

  const onConfirmClick = () => {
    dispatch(logoutUser());
    localStorage.clear();
    navigate("/login?success=true");
  };

  return (
    <Container>
      <LogoWrapper
        onClick={() => {
          navigate("/");
        }}
      >
        <Logo src={logo} />
      </LogoWrapper>
      <ManuWrapper>
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          style={{ width: 240 }}
          selectedKeys={SelectedOpenKey(path)}
        >
          {items.map((item: any) => {
            if (item.children) {
              return (
                <Menu.SubMenu
                  key={item.key}
                  icon={item.icon}
                  title={item.label}
                >
                  {item.children.map((child: any) => (
                    <Menu.Item key={child.key}>
                      <Link to={child.path}>{child.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }

            return (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            );
          })}

          <Menu.Item
            icon={<Icon src={logoutIcon} />}
            onClick={() => setLogout(true)}
          >
            Logout
          </Menu.Item>
        </Menu>
      </ManuWrapper>

      <Modal
        open={logout}
        onOk={onConfirmClick}
        onCancel={() => setLogout(false)}
        footer={false}
        centered
      >
        <ModalContent>
          <ModalTitle>Are you sure you want to logout?</ModalTitle>
          <div style={{ fontSize: "16px", marginTop: "6px" }}></div>
          <ConfirmationRow>
            <ButtonWrapper
              onClick={() => setLogout(false)}
              style={{ paddingTop: "14px" }}
            >
              <Reject style={{ height: "30px", width: "100px" }}>No</Reject>
            </ButtonWrapper>
            <ButtonWrapper
              onClick={onConfirmClick}
              style={{ paddingTop: "14px" }}
            >
              <Accept style={{ height: "30px", width: "100px" }}>yes</Accept>
            </ButtonWrapper>
          </ConfirmationRow>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Sidebar;
