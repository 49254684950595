import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  Dot,
  StateLine,
  Location,
  TrackConainer,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NameWrap, NameText, InnerContainer } from "styles/jobManagement";
import { DriverComponent } from "components/driverDetails";
import customerImage from "assets/images/customerImage.png";
import truck2 from "assets/images/truck2.png";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import { postApi } from "api/api.client";
import { APIS, jobDropDetails, locationDetails } from "api/api.constant";
import moment from "moment";
import { JobNote } from "components/jobNote";
import { LocationTable } from "components/locationTable";
import {
  calculateTotalPickupCount,
  capitalizeFirstLetter,
  getLocation,
  getdropLocation,
} from "components/commonFunction";
const UpcomingJobdetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.JOB_DETAILS}`, { jobId: id })
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  const locationsData = data?.locations;
  const jobDropOffData = data?.jobDropOff;

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    { label: "Items", data: calculateTotalPickupCount(locationsData) },
    {
      label: "Amount",
      data: `${Number(data?.amount || 0)?.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      })}`,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time_slot !== "ASAP" ? data?.start_time : data?.time_slot,
    },
    {
      label: "Weight",
      data: data?.weight + " Kg" || 0,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Upcoming Job Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <InnerContainer>
          <UserWrapper>
            <NameWrap>
              <NameText>
                Created By :{" "}
                {capitalizeFirstLetter(
                  data?.createdBy?.full_name ? data?.createdBy?.full_name : ""
                )}
              </NameText>
            </NameWrap>
            <UserComponent data={data?.createdBy} />
          </UserWrapper>
          <UserWrapper>
            <NameWrap>
              <NameText>
                Assigned to :{" "}
                {capitalizeFirstLetter(
                  data?.driverId?.full_name ? data?.driverId?.full_name : ""
                )}
              </NameText>
            </NameWrap>
            <DriverComponent data={data?.driverId} />

            <TableTitle>Vehicle Detail</TableTitle>
            <VahicleWrapper>
              {vehicleData.map(({ label, data }) => (
                <TextWrapper>
                  <Label>
                    <div>{label}</div>
                  </Label>
                  <DataWrapper>{data}</DataWrapper>
                </TextWrapper>
              ))}
            </VahicleWrapper>

            {data?.note?.length > 0 && (
              <JobNote type="Note" data={data?.note} />
            )}
            {data?.document?.length > 0 && <Documents data={data?.document} />}

            {locationsData?.length > 0 && (
              <LocationTable
                locationsData={locationsData}
                jobDropOffData={jobDropOffData}
              />
            )}
            <TrackWrapper>
              <TableTitle className="doc">Location Detail</TableTitle>
              {locationsData?.map((item: any, index: any) => {
                return (
                  <>
                    <TrackConainer key={index}>
                      <TractLine>
                        <Place>
                          {index === 0
                            ? "Job Started"
                            : getLocation(locationsData[index - 1])}
                        </Place>
                        <Line color="#606161" />
                        <IconWrap>
                          <img
                            src={truck2}
                            alt="truck_img"
                            width={50}
                            height={30}
                          />
                        </IconWrap>
                        <Place>{getLocation(item)}</Place>
                      </TractLine>
                      {locationDetails?.map((locationDetail, index) => (
                        <Location key={index}>
                          <LocationText color="#606161">
                            {locationDetail.text}
                          </LocationText>
                          <LineWrap>
                            <Dot color="#606161"></Dot>
                            <StateLine color="#606161"></StateLine>
                          </LineWrap>
                          <LocationText color="#606161" className="smallText">
                            {locationDetail.description === ""
                              ? getLocation(item)
                              : locationDetail.description}
                          </LocationText>
                        </Location>
                      ))}
                    </TrackConainer>
                  </>
                );
              })}
              {jobDropOffData?.map((item: any, index: any) => {
                return (
                  <>
                    <TrackConainer key={index}>
                      <TractLine>
                        <Place>
                          {index === 0
                            ? getLocation(
                                locationsData[locationsData?.length - 1]
                              )
                            : getdropLocation(jobDropOffData[index - 1])}
                        </Place>
                        <Line color="#606161" />
                        <IconWrap>
                          <img
                            src={truck2}
                            alt="truck_img"
                            width={50}
                            height={30}
                          />
                        </IconWrap>
                        <Place>{getdropLocation(item)}</Place>
                      </TractLine>
                      {jobDropDetails?.map((locationDetail, index) => (
                        <Location key={index}>
                          <LocationText color="#606161">
                            {locationDetail.text}
                          </LocationText>
                          <LineWrap>
                            <Dot color="#606161"></Dot>
                            <StateLine color="#606161"></StateLine>
                          </LineWrap>
                          <LocationText color="#606161" className="smallText">
                            {locationDetail.description === ""
                              ? getdropLocation(item)
                              : locationDetail.description}
                          </LocationText>
                        </Location>
                      ))}
                    </TrackConainer>
                  </>
                );
              })}
            </TrackWrapper>
          </UserWrapper>
        </InnerContainer>
      </Wrapper>
    </Container>
  );
};

export default UpcomingJobdetail;
