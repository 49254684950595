import styled from "styled-components";

export const RatingWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding-right: 200px;
  justify-content: space-between;
  align-items: center;
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 20px;
`;

export const RateAndSignWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;

  align-items: flex-start;
`;
export const RateWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

export const ReviewWrapper = styled.div`
  display: flex;
  font-weight: 400;
`;

export const RatingCommentWrapper = styled.div`
  background-color: #d7d7d7;
  width: 100%;
  padding: 20px;

  border-radius: 10px;
  font-size: 16px;
`;
export const CommentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
