import {
  UserWrapper,
  TextWrapper,
  Label,
  Data,
  VahicleWrapper,
  TrackWrapper,
  TractLine,
  Line,
  Place,
  IconWrap,
  LocationText,
  LineWrap,
  StateLine,
  Location,
  TrackConainer,
  DataWrapper,
} from "styles/commonStyle";
import { Container, TableTitle, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { UserComponent } from "components/userDetail";
import { Documents } from "components/documents";
import {
  BackButton,
  ButtonText,
  ButtonWrapper,
  TopSection,
} from "styles/unAssignedJob";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NameWrap, NameText, InnerContainer } from "styles/jobManagement";
import { DriverComponent } from "components/driverDetails";
import customerImage from "assets/images/customerImage.png";
import { FaTruckFast } from "react-icons/fa6";
import { Rating, RatingWrap } from "styles/driverCompletedJob";
import { Rate } from "antd";
import truck from "assets/images/truck.png";
import seprator from "assets/images/seprator.png";
import { Line2, Seprators, TrackDetails } from "styles/pages/hotshotManagement";
import { useDispatch } from "react-redux";
import { UserDetail } from "constants/tableData";
import { toggleLoader } from "../../../../redux/slices/loaderSlice";
import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import moment from "moment";

const UnassignedHotShot = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState<UserDetail["data"]>({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(`${APIS.HOTSHORT_DETAIL}`, { id: id })
      .then((res) => {
        let response: any = res;
        setData(response?.data);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  const vehicleData = [
    { label: "Vehicle Type", data: data?.vehicle },
    { label: "Date", data: moment(data?.dates).format("ll") },
    {
      label: "Amount",
      data: `${Number(data?.amount || 0)?.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      })}`,
    },
    {
      key: "time",
      label: "Time",
      data: data?.time,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Title>Unassigned Hotshots Details</Title>
          <ButtonWrapper>
            <BackButton onClick={() => navigate(-1)}>
              <IoIosArrowBack size={20} />
              <ButtonText> Back</ButtonText>
            </BackButton>
          </ButtonWrapper>
        </TopSection>
        <InnerContainer>
          <UserWrapper>
            <NameWrap>
              <NameText>Driver Detail</NameText>
            </NameWrap>
            <DriverComponent data={data?.driver} />
            <VahicleWrapper>
              {vehicleData.map(({ label, data }) => (
                <TextWrapper>
                  <Label>
                    <div>{label}</div>
                  </Label>
                  <DataWrapper>{data}</DataWrapper>
                </TextWrapper>
              ))}
            </VahicleWrapper>
            <Seprators src={seprator} />
            <TrackDetails>
              <TractLine className="trackline">
                <Place>
                  {data?.pickup_location ||
                    data?.pickup_address_1 +
                      " " +
                      data?.pickup_address_2 +
                      " " +
                      data?.pickup_city +
                      " " +
                      data?.pickup_state +
                      " " +
                      data?.pickup_zip}
                </Place>
                <Line2 color="#F7931E" />
                <IconWrap>
                  <img src={truck} alt="truck" width={50} height={50} />
                </IconWrap>
                <Place>
                  {data?.dopoff_location ||
                    data?.dopoff_address_1 +
                      " " +
                      data?.dopoff_address_2 +
                      " " +
                      data?.dopoff_city +
                      " " +
                      data?.dopoff_state +
                      " " +
                      data?.dopoff_zip}
                </Place>
              </TractLine>
            </TrackDetails>
            <Seprators src={seprator} />
          </UserWrapper>
        </InnerContainer>
      </Wrapper>
    </Container>
  );
};

export default UnassignedHotShot;
