import { Container, TableWrapper, Title } from "styles/dashboard";
import { Wrapper } from "styles/pages/userManagement";
import { DataType, TodayJobData, data } from "constants/tableData";
import Table, { ColumnsType } from "antd/es/table";
import { Action, UserImage, UserNameWrapper } from "styles/dashboard";
import viewIcone from "assets/images/view.png";
import { ActionsWrapper, Icon } from "styles/pages/userManagement";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { PAGINATION_SIZE, filterInitialValue } from "constants/common";
import { FilterValue } from "interfaces/iCommon";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { postApi } from "api/api.client";
import { APIS } from "api/api.constant";
import UserIcon from "../../../assets/images/no-user.jpg";
import { capitalizeFirstLetter } from "components/commonFunction";

export const UnassignedHotshotList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page");
  const [filter, setfilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(Number(currentPage) || 1);
  const [pageSize, setPageSize] = useState(PAGINATION_SIZE);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filterValue, setFilterValue] =
    useState<FilterValue>(filterInitialValue);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.HOTSHOT_UNASSIGNED, {
      page: page,
      size: pageSize,
    })
      .then((res) => {
        let response: any = res;
        setTableData(response?.data?.jobInfo);
        setTotalCount(response?.data?.pagination?.totalCount);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [triggerFilter, page, pageSize, refresh]);

  useEffect(() => {
    const handleSelect = () => {
      queryParams.set("page", String(page));
      const newUrl = `${location.pathname}?${queryParams}`;
      window.history.replaceState(null, "", newUrl);
    };
    handleSelect();
  }, [page]);

  const Todaycolumns: ColumnsType<DataType> = [
    {
      title: "Driver Name",
      key: "name",
      render: (_, record: any) => (
        <UserNameWrapper>
          <UserImage
            src={
              record?.driver?.photo !== null ? record?.driver?.photo : UserIcon
            }
          />
          {capitalizeFirstLetter(
            record?.driver?.full_name ? record?.driver?.full_name : ""
          )}
        </UserNameWrapper>
      ),
    },
    {
      title: "First Location",
      dataIndex: "FirstLocation",
      key: "First Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.pickup_location ||
          record?.pickup_address_1 +
            " " +
            record?.pickup_address_2 +
            " " +
            record?.pickup_city +
            " " +
            record?.pickup_state +
            " " +
            record?.pickup_zip;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Last Location",
      dataIndex: "LastLocation",
      key: "last Location",
      render: (_, record: any) => {
        const firstLocation =
          record?.dopoff_location ||
          record?.dopoff_address_1 +
            " " +
            record?.dopoff_address_2 +
            " " +
            record?.dopoff_city +
            " " +
            record?.dopoff_state +
            " " +
            record?.dopoff_zip;
        return <div>{firstLocation}</div>;
      },
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (_, record: any) => {
        return <div>{record?.vehicle}</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (_, record: any) => {
        return <div>{record?.time}</div>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record: any) => {
        return (
          <div>
            {Number(record?.amount || 0)?.toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        );
      },
    },
    {
      title: <Action>Actions</Action>,
      key: "Action",
      render: (_, record) => {
        return (
          <ActionsWrapper>
            <Icon
              src={viewIcone}
              onClick={() =>
                navigate(`/hotshot-management/unassigned/${record?._id}`)
              }
            />
          </ActionsWrapper>
        );
      },
    },
  ];

  return (
    <Container>
      <Wrapper>
        <Title>Unassigned Hotshots List</Title>
        <TableWrapper>
          <Table
            columns={Todaycolumns}
            dataSource={tableData}
            pagination={{
              total: totalCount,
              onChange: (page: any, pageSize: any) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </TableWrapper>
      </Wrapper>
    </Container>
  );
};
