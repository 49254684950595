import Navbar from "components/navbar";
import { useLocation } from "react-router-dom";
import { Container, Wrapper, Title, Title2, Content } from "styles/view";

export const TermsAndConditionsView = () => {
  const location = useLocation();
  const { payload } = location.state || {};

  return (
    <Container>
      {/* <Navbar classname={"customeNav"} /> */}
      <Wrapper>
        <Title>Terms & Conditions</Title>
        <Content dangerouslySetInnerHTML={{ __html: payload }}></Content>
      </Wrapper>
    </Container>
  );
};
